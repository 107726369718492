import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TextInputLengthCalculator } from './tokens';

export function AynMaxLengthValidator(maxLength: number, lengthCalculator: TextInputLengthCalculator) {
  return function _AynMaxLengthValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && lengthCalculator(control.value) > maxLength) {
      return { aynMaxLength: { requiredLength: maxLength, actualLength: lengthCalculator(control.value) } };
    }
    return null;
  };
}

export function AynMinLengthValidator(minLength: number, lengthCalculator: TextInputLengthCalculator) {
  return function _AynMinLengthValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && lengthCalculator(control.value) < minLength) {
      return { aynMinLength: { requiredLength: minLength, actualLength: lengthCalculator(control.value) } };
    }

    return null;
  };
}
