<header class="aayn-mobile-topbar">
  <a routerLink="/main/dashboard" class="ayn-layout--aside-logo">
    <ayn-icon
      *ngIf="!(logo$ | async)"
      name="logo"
      class="aayn-mobile-topbar--logo-icon"
      srcPrefix="/assets/images/svgs/"
    ></ayn-icon>

    <img
      *ngIf="logo$ | async"
      [src]="(logo$ | async) || defaultLogo"
      #logoRef
      (error)="logoRef.src = defaultLogo"
      [alt]="'Logo' | translate"
      class="aayn-mobile-topbar--logo"
    />
  </a>
  <a class="aayn-mobile-topbar--close" *ngIf="isMobileMenu" (click)="closeMenu(false)">
    <ayn-icon name="exit"></ayn-icon>
  </a>
</header>

<ng-container *ngIf="isMobileMenu">
  <div
    class="aayn-mobile-topbar--header"
    *ngIf="{
      isCancelledScheduled: isCancelledScheduled$ | async,
      isBusinessSelectionAvailable: layoutService.isBusinessSelectionAvailable$ | async
    } as _"
  >
    <aayn-business-selection
      *ngIf="!_.isCancelledScheduled && !detectedAdBlock"
      [businessSelectionDisabled]="!_.isBusinessSelectionAvailable"
    ></aayn-business-selection>
    <aayn-language-selection></aayn-language-selection>
    <a
      routerLink="/main/upgrade"
      ayn-button="primary"
      icon="upgrade"
      icon-direction="left"
      class="aayn-mobile-topbar--header-upgrade"
      *ngIf="!(isAppsumoUser$ | async)"
    >
      Upgrade
    </a>
  </div>

  <main class="aayn-mobile-topbar--main">
    <div class="aayn-mobile-topbar--menu">
      <ul class="aayn-mobile-topbar--menu__list">
        <li
          *ngFor="let route of routes"
          routerLinkActive="active"
          [ngClass]="{
             'hover:disabled disabled': route.disabled || !hasAnyBusiness || (route.needAdminAccount && isInviterUser),
          }"
        >
          <a
            (click)="route.action ? actionClick(route.action) : mobileMenuClick.emit(!isMobileMenu)"
            [routerLink]="
              route.disabled || !hasAnyBusiness || route.name === 'Menu' || (route.needAdminAccount && isInviterUser)
                ? null
                : route.url
            "
          >
            <span>{{ route.name }}</span>
            <ayn-icon [name]="route.icon" className="aside__menu_icon"></ayn-icon>

            <ng-container *ngIf="route.url === '/main/university'">
              <img src="/assets/images/svgs/medal-{{ true ? 'active' : 'passive' }}.svg" alt="University" class="" />
            </ng-container>
          </a>
        </li>
      </ul>
    </div>

    <div class="aayn-mobile-topbar--package">
      <aayn-package></aayn-package>
    </div>

    <div class="aayn-mobile-topbar--buttons">
      <button ayn-button="accent" [width]="170" (click)="secureOverlay.toggle($event)">
        {{ 'Secure' | translate }}
        <ayn-icon name="shield"></ayn-icon>
      </button>
    </div>
  </main>
</ng-container>

<ayn-overlay #secureOverlay styleClass="popup aayn-user-menu--body--secure-popup">
  <div class="popup--inner">
    <div class="aayn-user-menu--body--secure-popup__header">
      <ayn-icon name="secure" class="aayn-user-menu--body--secure-popup__icon"></ayn-icon>
      <h4 class="aayn-user-menu--body--secure-popup__title">{{ 'Secure' | translate }}</h4>
    </div>

    <p class="aayn-user-menu--body--secure-popup__description">
      {{
        'ADYOUNEED cares about your privacy and security, which is why all your data is encrypted. Neither the ADYOUNEED team or any third party has access to your data with out authorized consent from you.'
          | translate
      }}
    </p>
  </div>
</ayn-overlay>
