import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, GraphQLService } from '@core/backend';
import { blobToFile } from '@core/utils';
import { map } from 'rxjs/operators';
import { searchImagesQuery } from '@core/queries/ayn';
import { OpenAI } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class AynService extends BaseHttpService {
  constructor(private http: HttpClient, private graphqlService: GraphQLService) {
    super(http, '/ayn');
  }

  getImage(imageUrl: string) {
    return this.http.post(`${this.apiUrl}/fetch-image`, { imageUrl }, { responseType: 'blob' });
  }

  getImageAsFile(imageUrl: string, fileName: string) {
    return this.getImage(imageUrl).pipe(
      map((image) => {
        return blobToFile(image, fileName);
      })
    );
  }

  searchImages(input: OpenAI.UrlInput) {
    return this.graphqlService.get(searchImagesQuery(input)).pipe(map((result) => result.data.getImages));
  }
}
