<div class="aayn-audience--location-selection--add-more">
  <ayn-badge
    [color]="
      addLocationOverlay.render ? ((systemTheme$ | async) === SystemTheme.DARK ? 'gray-300' : 'gray-500') : 'accent'
    "
    icon="plus"
    [text]="'Add Location to ' + (PlatformKeyByValues[platform!] | titlecase)"
    (click)="addLocationOverlay.toggle($event)"
  ></ayn-badge>

  <ayn-overlay #addLocationOverlay>
    <div
      class="aayn-audience--location-selection--add-more--overlay-inner"
      [ngClass]="{ '__has-locations': locations.length }"
    >
      <ayn-form-field>
        <input type="text" ayn-input placeholder="Type to search for location" [formControl]="searchControl" />

        <ayn-icon name="map-marker"></ayn-icon>
      </ayn-form-field>
      <ul *ayn-loader="loading">
        <li
          *ngFor="let location of locations"
          (click)="selectLocation(location)"
          [ngClass]="{ __selected: _hasExistsLocation(location) }"
        >
          {{ location.name }}

          <button type="button">
            Select
            <ayn-icon name="check"></ayn-icon>
          </button>
        </li>
      </ul>

      <div class="p-3" *ngIf="platform === platforms.TikTok && !loading && searchControl.value && !locations.length">
        {{ 'No location found or your ad account not allowed to target searched location' | translate }}
      </div>
    </div>
  </ayn-overlay>
</div>

<ayn-badge
  *ngFor="let location of adCreationModel.audience.geoLocations.inclusions[platform!]; let index = index"
  color="primary"
  [text]="
    platform === platforms.Meta ? getMetaLocationFullName(location) : location.platformSpecificName || location.name
  "
  icon="cross-mini-bold"
  class="ml:3"
  (click)="onLocationClick(index)"
></ayn-badge>
