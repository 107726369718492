import { ChangeDetectorRef, Component } from '@angular/core';
import { TikTok } from '@core/models';
import { TiktokAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/tiktok/components/ad-preview/ad-preview.service';
import { mapAdTypes, selectedItem } from '@pages/create-ad/components/select-objective/utils';
import { ICreateAdSelectItem } from '@pages/create-ad/models';
import { TiktokStateService } from '@pages/create-ad/state';
import { provideLengthCalculator, TextInputLengthCalculator } from '@ayn-ui/lib/modules';
import { countWithCJK } from '@core/utils';

const AD_TYPES__TIKTOK: ICreateAdSelectItem<TikTok.CreateAd.AdType>[] = [
  {
    id: 1,
    title: 'Dynamic Creative ads',
    description: 'Perfect for advertising 1 product/service using a single image or video.',
    icon: 'dynamic',
    empty: false,
    active: true,
    type: TikTok.CreateAd.AdType.DYNAMIC_CREATIVE_ADS
  },
  {
    id: 2,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false,
    type: undefined
  },
  {
    id: 3,
    title: '',
    description: '',
    icon: '',
    empty: true,
    active: false,
    type: undefined
  }
];
export const TIKTOK_INPUT_LENGTH_CALCULATOR: TextInputLengthCalculator = (val: string) => {
  if (!val.length) {
    return 0;
  }
  return countWithCJK(val, 2);
};

@Component({
  selector: 'aayn-select-ad-type--tiktok',
  templateUrl: './select-ad-type-content.component.html',
  providers: [TiktokAdPreviewService, provideLengthCalculator(TIKTOK_INPUT_LENGTH_CALCULATOR)]
})
export class SelectAdTypeContentTiktokComponent {
  selectedAdType!: TikTok.CreateAd.AdType;

  adTypes$ = this.tiktokStateService.adCreationModel$.pipe(
    mapAdTypes(AD_TYPES__TIKTOK, 'ad'),
    selectedItem((ad) => {
      if (ad.type !== undefined) {
        this.selectedAdType = ad.type;
      }
    })
  );

  protected AdTypes = TikTok.CreateAd.AdType;

  constructor(private tiktokStateService: TiktokStateService, private cdr: ChangeDetectorRef) {}

  adTypeChangeStatus(item: ICreateAdSelectItem<TikTok.CreateAd.AdType>) {
    if (this.tiktokStateService.adCreationModel.selectedTypes.ad !== item.type) {
      this.tiktokStateService.changeAdType(item.type!);
    }
  }
}
