import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BingService,
  FacebookService,
  GoogleService,
  LinkedinService,
  Platforms,
  SmartCampaign,
  TikTok,
  TiktokService,
  UserState
} from '@core/index';
import { Bing, Facebook, Google, Linkedin, PlatformKeyByValues } from '@core/models';
import { LoaderState } from '@core/state/loader.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { SmartResponseCatcherDirective } from '@shared/directives';
import { SystemTheme } from '@core/models/base.model';

type LocationType<T = any> = {
  name: string;
  platformsObject: T;
};

@UntilDestroy()
@Component({
  selector: 'aayn-location-selection--add-more--smart-campaign',
  templateUrl: 'location-selection-add-more.component.html'
})
export class LocationSelectionAddMoreForSmartCampaignComponent implements OnInit {
  @Input() platform?: Platforms;

  @Input() form?: FormGroup;

  platforms = Platforms;
  SystemTheme = SystemTheme;

  protected PlatformKeyByValues = PlatformKeyByValues;

  adCreationModel = this.smartCampaignStateService.adCreationModel;

  protected searchControl = new FormControl('', [Validators.required]);

  locations: LocationType[] = [];

  @Select(LoaderState.getAny(['GetGeoLocations', 'LocationSearch', 'LinkedinSearchByFacet']))
  locationSearchLoader?: Observable<boolean>;

  @Select(UserState.getSystemTheme) systemTheme$!: Observable<SystemTheme>;

  loading = false;

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private facebookService: FacebookService,
    private googleService: GoogleService,
    private bingService: BingService,
    private linkedinService: LinkedinService,
    private tiktokService: TiktokService,
    private cdr: ChangeDetectorRef,
    @Optional() private smartResponseCatcherDirective?: SmartResponseCatcherDirective
  ) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });

    this.listenSearchControl();

    this.listenLocationSearchLoader();
  }

  selectLocation(location: LocationType) {
    let selectedLocation = this.getLocation(location);

    if (selectedLocation) {
      this.adCreationModel.audience.geoLocations.inclusions[this.platform!] =
        this.adCreationModel.audience.geoLocations.inclusions[this.platform!].filter((o) => o != selectedLocation);
      return;
    }

    if (!this.adCreationModel.audience.geoLocations.inclusions[this.platform!])
      this.adCreationModel.audience.geoLocations.inclusions[this.platform!] = [];

    this.adCreationModel.audience.geoLocations.inclusions[this.platform!].push(location.platformsObject);

    this._updateForm();
  }

  getLocation(location: LocationType) {
    switch (this.platform) {
      case Platforms.Meta:
        return this.adCreationModel.audience.geoLocations.inclusions[this.platform]?.find(
          (o) => o.key == (location as LocationType<Facebook.GeoLocationItem>).platformsObject.key
        );

      case Platforms.Google:
        return this.adCreationModel.audience.geoLocations.inclusions[this.platform]?.find(
          (o) => o.id == (location as LocationType<Google.CreateAd.ILocationSearch>).platformsObject.id
        );
      case Platforms.Bing:
        return this.adCreationModel.audience.geoLocations.inclusions[this.platform]?.find(
          (o) => o.id == (location as LocationType<Bing.AudienceCampaign.BingLocationConstant>).platformsObject.id
        );

      case Platforms.LinkedIn:
        return this.adCreationModel.audience.geoLocations.inclusions[this.platform]?.find(
          (o) => o.urn == (location as LocationType<Linkedin.CreateAd.SearchByFacetEdgeNode>).platformsObject.urn
        );

      case Platforms.TikTok:
        return this.adCreationModel.audience.geoLocations.inclusions[this.platform]?.find(
          (o) =>
            o.id == (location as LocationType<TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto>).platformsObject.id
        );
    }

    return undefined;
  }

  _hasExistsLocation(location: LocationType) {
    return !!this.getLocation(location);
  }

  onLocationClick(index: number) {
    if (!this.platform) return;

    this.adCreationModel.audience.geoLocations.inclusions[this.platform].splice(index, 1);

    this._updateForm();
  }

  private _updateForm() {
    this.form?.patchValue({
      geoLocations: this.adCreationModel.audience.geoLocations.inclusions
    });

    this.smartResponseCatcherDirective?.processTabs();
  }

  private _getMetaLocations(term: string) {
    this.facebookService.getGeoLocations$(term).subscribe((o) => {
      this.locations = o.data.geoLocations.edges.map((l) => {
        return {
          name: l.node.name.concat(', ') + (l.node.region || '').concat(', ') + l.node.countryName,
          platformsObject: l.node
        };
      });

      this.cdr.detectChanges();
    });
  }

  private _getGoogleLocations(term: string) {
    this.googleService.locationSearch({ searchKey: term, limit: 20 }).subscribe((o) => {
      this.locations = (o || []).map((l) => {
        return {
          name: l.fullName,
          platformsObject: { name: l.fullName, ...l }
        };
      });

      this.cdr.detectChanges();
    });
  }

  private _getBingLocations(term: string) {
    this.bingService.locationSearch$({ searchKey: term, limit: 20 }).subscribe((o) => {
      this.locations = o.map((l) => {
        return {
          name: l.fullName,
          platformsObject: { name: l.fullName, ...l }
        };
      });

      this.cdr.detectChanges();
    });
  }

  private _getLinkedInLocations(term: string) {
    this.linkedinService.searchByFacet({ facet: 'Locations', query: term }).subscribe((o) => {
      this.locations = o.edges.map((l) => {
        return {
          name: l.node.name,
          platformsObject: l.node
        };
      });

      this.cdr.detectChanges();
    });
  }

  private _getTikTokLocations(term: string) {
    const objective =
      this.adCreationModel.campaign.objective === SmartCampaign.Client.CampaignObjective.ConversionWebsite
        ? TikTok.CreateAd.Backend.ObjectiveType.WebConversions
        : TikTok.CreateAd.Backend.ObjectiveType.Traffic;
    this.tiktokService.getLocations$({ searchKey: term, objective }).subscribe((locations) => {
      this.locations = locations.map((l) => ({ name: l.name, platformsObject: l }));
    });
  }

  private listenSearchControl() {
    this.searchControl.valueChanges
      .pipe(
        filter((term): term is string => !!term),
        tap(() => (this.loading = true)),
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe((term) => {
        switch (this.platform) {
          case Platforms.Meta:
            this._getMetaLocations(term);
            break;
          case Platforms.Google:
            this._getGoogleLocations(term);
            break;
          case Platforms.Bing:
            this._getBingLocations(term);
            break;
          case Platforms.LinkedIn:
            this._getLinkedInLocations(term);
            break;
          case Platforms.TikTok:
            this._getTikTokLocations(term);
            break;

          default:
            break;
        }
      });
  }

  private listenLocationSearchLoader() {
    this.locationSearchLoader?.pipe(untilDestroyed(this)).subscribe((loading) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });
  }

  getMetaLocationFullName<T>(location: SmartCampaign.Client.GeoLocationItem<T> & { name?: string }) {
    if (!location.platformSpecificName) {
      return location.name;
    }
    const fbLocation = location as unknown as SmartCampaign.Client.GeoLocationItem<Facebook.GeoLocationItem>;
    let region = fbLocation.region || fbLocation.primaryCity;
    region = region ? ` ${region},` : '';
    return `${location.platformSpecificName},${region} ${fbLocation.countryName}`;
  }
}
