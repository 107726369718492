import { Facebook } from '@core/models';
import { makeQuery } from '@core/queries/types';

type Output = {
  customConversions: {
    edges: {
      node: Facebook.ICustomConversion;
    }[];
  };
};

export const getCustomConversions = makeQuery<Output>(`
query customConversions {
  facebook {
    customConversions {
      edges {
        node {
          id
          name
          rule
          customEventType
          eventSourceType
          pixelId
          isArchived
          isUnavailable
        }
      }
    }
  }
}

`);
