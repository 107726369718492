import { Injectable } from '@angular/core';
import { pipe } from 'fp-ts/lib/function';
import { map } from 'fp-ts/lib/Array';
import { fromEntries } from 'fp-ts/lib/Record';
import { deriveTuple, rgbFormat } from '@core/utils';
import { UserState } from '@core/state';
import { Store } from '@ngxs/store';
import { SystemTheme } from '@core/models/base.model';

export type ThemeColorVariables = 'primary-rgb' | 'accent-rgb' | 'gray-rgb' | 'gray-500' | 'body-bg-img' | 'body-bg';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor(private store: Store) {
    this.store.select(UserState.getSystemTheme).subscribe((theme) => this.setSystemTheme(theme || SystemTheme.LIGHT));
  }

  colorNames: ThemeColorVariables[] = ['primary-rgb', 'accent-rgb', 'gray-rgb', 'gray-500', 'body-bg-img', 'body-bg'];

  modifyThemeColor(color: ThemeColorVariables, value: string): void {
    document.body.style.setProperty(`--${color}`, value);
  }

  resetThemeColor(color: ThemeColorVariables): void {
    document.body.style.removeProperty(`--${color}`);
  }

  resetAllThemeColors(): void {
    this.colorNames.forEach((color) => {
      this.resetThemeColor(color);
    });
  }

  setUserTheme(): void {
    if (this.store.selectSnapshot(UserState.getSystemTheme) === SystemTheme.DARK) {
      return;
    }
    const branding = this.store.selectSnapshot(UserState.getUserTheme);
    if (branding) {
      branding.mainColor && this.modifyThemeColor('primary-rgb', rgbFormat(branding.mainColor));
      branding.additionalColor && this.modifyThemeColor('accent-rgb', rgbFormat(branding.additionalColor));

      if (branding.backgroundColor) {
        this.modifyThemeColor('body-bg-img', branding.backgroundColor);
        this.modifyThemeColor('body-bg', branding.backgroundColor);
      }
      if (branding.paragraphColor) {
        this.modifyThemeColor('gray-500', branding.paragraphColor);
      }
    }
  }

  setSystemTheme(systemTheme: string): void {
    if (systemTheme) {
      document.body.setAttribute('data-theme', `${systemTheme}`);
      if (systemTheme === SystemTheme.DARK) {
        this.resetAllThemeColors();
      } else {
        this.setUserTheme();
      }
    }
  }

  resetFavicon(): void {
    this.modifyFavicon('favicon.ico');
  }

  getThemeColor(color: ThemeColorVariables): string {
    return getComputedStyle(document.body).getPropertyValue(`--${color}`);
  }

  getThemeColors(): Record<ThemeColorVariables, string> {
    return pipe(this.colorNames, map(deriveTuple(this.getThemeColor)), fromEntries);
  }

  modifyFavicon(icon: string): void {
    const link = document.querySelector('#aynFavicon') as HTMLLinkElement;
    link.href = icon;
  }
}
