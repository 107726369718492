import { makeQuery } from '@core/queries';
import { Base, OpenAI } from '@core/models';

export const searchImagesQuery = makeQuery<{ getImages: Base.CreateAd.SearchImage[] }, OpenAI.UrlInput>(`
query GetImages($url:String!){ 
  ayn {
    getImages(url: $url){
        src
        width
        height
        parentString
    }
  }
}
`);
