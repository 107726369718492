import { ReplaySubject } from 'rxjs';
import { startWith, take } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Facebook, FacebookService } from '@core/index';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FacebookFormService, FacebookStateService, FacebookValidationService } from '@pages/create-ad/state';

export const DEFAULT_CUSTOM_EVENT_TYPE_KEY = '@@AYN_DEFAULT';

@UntilDestroy()
@Component({
  selector: 'aayn-conversion-my-website--facebook',
  templateUrl: 'conversion-my-website.component.html'
})
export class ConversionMyWebsiteFacebookComponent implements OnInit {
  protected adCreationModel = this.facebookStateService.adCreationModel;

  protected adPixels: Facebook.IAdPixel[] = [];

  protected conversionEvents: Facebook.ICustomConversion[] = [];

  conversion$ = new ReplaySubject<Partial<Facebook.ICustomConversion>>(1);

  protected form = new FormGroup({
    pixelId: new FormControl(this.adCreationModel.adSet.promotedObject?.pixelId, [Validators.required]),
    conversionEvent: new FormControl<Facebook.ICustomConversion | null>(null, [Validators.required]),
    campaignName: new FormControl(this.adCreationModel?.campaign.name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(100)
    ])
  });

  constructor(
    private facebookStateService: FacebookStateService,
    private facebookService: FacebookService,
    private facebookValidationService: FacebookValidationService,
    private facebookFormService: FacebookFormService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.facebookFormService.registerForm(this.form);

    this.form.statusChanges.pipe(startWith(this.form.status)).subscribe((status) => {
      this.facebookValidationService.objectiveValid$.next(status === 'VALID');
    });

    this.facebookValidationService.objectiveMarkAsTouched$.subscribe((result) => {
      result && this.form.markAllAsTouched();
    });

    this.facebookStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;

      this.adCreationModel.adSet.optimizationGoal = Facebook.OptimizationGoal.OFFSITE_CONVERSIONS;
      const { promotedObject } = this.adCreationModel.adSet;

      this.conversion$.next({
        customEventType: promotedObject.customEventType,
        id: promotedObject.customConversionId || ''
      });
    });

    if (!this.adCreationModel.adSet.promotedObject) {
      this.adCreationModel.adSet.promotedObject = {};
    }

    this.facebookService.getAdPixels$().subscribe((result) => {
      this.adPixels = result.data.adPixels.edges.map((o) => o.node);
      this.cdr.detectChanges();
    });

    this.facebookService.getCustomConversions$().subscribe((result) => {
      this.conversionEvents = [
        ...result.data.customConversions.edges.map((o) => o.node),
        ...Facebook.CUSTOM_EVENT_TYPES.map(this.createOption)
      ];
      this.conversion$.pipe(take(1)).subscribe((conversion) => {
        let conversionEvent: Facebook.ICustomConversion | undefined;
        if (conversion.id) {
          conversionEvent = this.conversionEvents.find((o) => o.id === conversion.id);
        } else if (conversion.customEventType) {
          conversionEvent = this.conversionEvents.find((o) => o.name === conversion.customEventType);
        }
        if (conversionEvent) {
          this.form.patchValue({
            conversionEvent: conversionEvent
          });
        }
      });
    });
  }

  onChangedSelectedConversionEvent($event?: Facebook.ICustomConversion) {
    if (!$event) return;

    if ($event?.customEventType == DEFAULT_CUSTOM_EVENT_TYPE_KEY) {
      this.adCreationModel.adSet.promotedObject.customEventType = $event.name;
      this.adCreationModel.adSet.promotedObject.customConversionId = undefined;
      this.adCreationModel.adSet.promotedObject.pixelRule = undefined;
      return;
    }

    this.adCreationModel.adSet.promotedObject.customConversionId = $event.id;
    this.adCreationModel.adSet.promotedObject.customEventType =
      $event.customEventType || Facebook.CustomEventType.Other;
    this.adCreationModel.adSet.promotedObject.pixelRule = $event.rule;
  }

  createOption(customEvent: Facebook.CustomEventType) {
    return {
      id: '',
      name: customEvent,
      customEventType: DEFAULT_CUSTOM_EVENT_TYPE_KEY,
      rule: ''
    } as Facebook.ICustomConversion;
  }

  isConversionEventDisabled(conversionEvent: Facebook.ICustomConversion) {
    const isCustomEvent = !!conversionEvent.id;
    const isConversionEventUsable = !conversionEvent.isArchived && !conversionEvent.isUnavailable;
    const pixelId = this.form.get('pixelId')?.value;
    const isConversionEventPixelSelected = pixelId && pixelId === conversionEvent.pixelId;
    return !isConversionEventUsable || (isCustomEvent && !isConversionEventPixelSelected);
  }

  getTooltipMessage(conversionEvent: Facebook.ICustomConversion) {
    if (conversionEvent.isArchived) {
      return 'This custom conversion has been archived and is no longer available for use.';
    }
    if (conversionEvent.isUnavailable) {
      return 'This custom conversion is unavailable for use.';
    }

    const conversionPixel = this.adPixels.find((o) => o.id === conversionEvent.pixelId);

    return conversionPixel
      ? `This custom conversion can be used with ${conversionPixel.name} pixel`
      : 'This custom conversion is not available for the selected pixel.';
  }
}
