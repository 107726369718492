import { environment } from '@environment';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FacebookCreateAd } from '@core/ad-platforms';
import { Facebook, FacebookService, PlatformStep } from '@core/index';
import { CanvaService } from '@core/services/canva.service';
import { BusinessState } from '@core/state/business.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { FacebookStateService, FacebookValidationService } from '@pages/create-ad/state';
import { FacebookFormService } from '@pages/create-ad/state/platforms/facebook/facebook.form';
import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { moveArrayItem, moveFormArrayItem } from '@shared/carousel-drag.util';
import { AdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/facebook/components/ad-preview/ad-preview.service';
import { UploadAdsImagesFacebookComponent } from '@pages/create-ad/components/select-adtype/platforms/facebook/components/modal';
import { Subscription } from 'rxjs';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { adTypeScrollHandler } from '@shared/handlers/ad-type-scroll.handler';

@UntilDestroy()
@Component({
  selector: 'aayn-carousel-ads--facebook',
  templateUrl: 'carousel-ads.component.html',
  providers: [
    {
      provide: INVALID_SCROLL_HANDLER,
      useFactory: adTypeScrollHandler
    }
  ]
})
export class CarouselAdsFacebookComponent implements OnInit {
  protected businessPageDetail?: Facebook.IBusinessPageDetail;

  protected callToActions = Facebook.CallToActionTypes;

  formListener?: Subscription;

  @ViewChildren(CdkDrag) drags!: QueryList<CdkDrag>;

  protected adCreationModel = this.facebookStateService
    .adCreationModel as unknown as FacebookCreateAd.ICreateAdModel<Facebook.AdCarouselCreativeInput>;

  protected form = new FormGroup({
    name: new FormControl(this.adCreationModel.ad.creative.name, [Validators.required]),
    callToAction: new FormControl(this.adCreationModel.ad.creative.callToAction, [Validators.required]),
    identity: new FormGroup({
      pageId: new FormControl(this.adCreationModel.ad.creative.identity.pageId),
      instagramAccountId: new FormControl(this.adCreationModel.ad.creative.identity.instagramAccountId)
    }),
    primaryText: new FormControl(this.adCreationModel.ad.creative.primaryText, [Validators.required]),
    carouselCards: new FormArray([
      new FormGroup({
        description: new FormControl(this.adCreationModel.ad.creative.carouselCards?.at(0)?.description, [
          Validators.required
        ]),
        headline: new FormControl(this.adCreationModel.ad.creative.carouselCards?.at(0)?.headline, [
          Validators.required
        ]),
        imageHash: new FormControl(this.adCreationModel.ad.creative.carouselCards?.at(0)?.imageHash),
        imageUrl: new FormControl('', [Validators.required])
      }),
      new FormGroup({
        description: new FormControl(this.adCreationModel.ad.creative.carouselCards?.at(1)?.description, [
          Validators.required
        ]),
        headline: new FormControl(this.adCreationModel.ad.creative.carouselCards?.at(1)?.headline, [
          Validators.required
        ]),
        imageHash: new FormControl(this.adCreationModel.ad.creative.carouselCards?.at(1)?.imageHash),
        imageUrl: new FormControl('', [Validators.required])
      })
    ]),
    destination: new FormGroup({
      displayUrl: new FormControl(this.adCreationModel.ad.creative.destination.displayUrl),
      url: new FormControl(this.adCreationModel.ad.creative.destination.url)
    })
  });

  private _adAccount = this.store.selectSnapshot(BusinessState.selectedBusinessActivePlatform);

  private _images?: Facebook.IdWithUrl[];

  get carouselCards() {
    return this.form.controls['carouselCards'] as FormArray;
  }

  constructor(
    private facebookService: FacebookService,
    private facebookStateService: FacebookStateService,
    private facebookValidationService: FacebookValidationService,
    private facebookForm: FacebookFormService,
    private cdr: ChangeDetectorRef,
    private canvaService: CanvaService,
    private modalService: NgbModal,
    private store: Store,
    public adPreviewService: AdPreviewService
  ) {}

  async ngOnInit() {
    try {
      await this.canvaService.setupSdk();
    } catch (error) {
      if (!environment.production) {
        console.error(`CreateAd.Facebook.CarouselAds.CanvaService`, error);
      }
    }

    this.listenForm();

    this.listenModel();

    this.facebookForm.registerForm(this.form);

    this.adCreationModel.adSet.isDynamicCreative = false;
    this.adCreationModel.ad.creative.optimizeCreativeForEachPerson = false;

    this.facebookValidationService.registerForm(this.form, PlatformStep.AdType, this);
  }

  addPaginationItem() {
    if (this.form.controls.carouselCards.controls.length === 10) {
      return;
    }
    this.form.controls.carouselCards.push(
      new FormGroup({
        description: new FormControl<string | undefined>('', [Validators.required]),
        headline: new FormControl<string | undefined>('', [Validators.required]),
        imageHash: new FormControl<string | undefined>(''),
        imageUrl: new FormControl('')
      }),
      { emitEvent: false }
    );
  }

  deletePaginationItem(index: number) {
    this.form.controls.carouselCards.removeAt(index);
    this._images = this._images?.filter((_, i) => i !== index);
  }

  setDestinationUrl(url: string) {
    this.form.controls.destination.controls.url.setValue(url);
  }

  dropped(event: CdkDragDrop<any, any>) {
    moveFormArrayItem(this.form.controls.carouselCards, event.previousIndex, event.currentIndex);
    this._images = moveArrayItem(this._images!, event.previousIndex, event.currentIndex);
  }

  openFileModal() {
    const modalRef = this.modalService.open(UploadAdsImagesFacebookComponent);

    if (this._images) (modalRef.componentInstance as UploadAdsImagesFacebookComponent).uploadedImages = this._images;

    modalRef.closed.pipe(take(1)).subscribe((result: { images: Facebook.IdWithUrl[] }) => {
      if (!result?.images) return;
      const images = result.images.map((o) => {
        const url = this._images?.find((i) => i.id === o.id)?.url;
        if (url) {
          o.url = url;
        }
        return o;
      });
      this.arrangeCarouselCards(images);
      this.updateImages(images);
    });
  }

  updateImages(images: Facebook.IdWithUrl[]) {
    this._images = images;

    this.form.controls.carouselCards.controls.forEach((card, index) => {
      card.patchValue(
        {
          imageUrl: images[index]?.url,
          imageHash: images[index]?.id
        },
        { emitEvent: true }
      );
    });

    this.cdr.detectChanges();
  }

  arrangeCarouselCards(cards: Array<any>) {
    const diff = cards.length - this.form.controls.carouselCards.length;
    if (diff > 0) {
      new Array(diff).fill(0).forEach(() => {
        this.addPaginationItem();
      });
      this.listenForm();
    } else if (diff < 0) {
      new Array(Math.abs(diff)).fill(0).forEach((_, index) => {
        this.form.controls.carouselCards.at(this.form.controls.carouselCards.length - 1 - index).patchValue({
          imageHash: '',
          imageUrl: ''
        });
      });
    }
  }

  getImages(index: number) {
    const imageHash = this.adCreationModel.ad.creative.carouselCards?.[index]?.imageHash;
    if (imageHash) {
      this.facebookService.getAdImages$([imageHash]).subscribe((images) => {
        images.data.adImages.edges.forEach((image) => {
          const control = this.carouselCards.controls.find((o) => o.value.imageHash === image.node.hash);
          control!.patchValue(
            {
              imageUrl: image.node.url
            },
            { emitEvent: true }
          );
          this.cdr.detectChanges();
        });
      });
    }
  }

  listenForm() {
    this.formListener?.unsubscribe();
    this.formListener = this.form.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
      this.adCreationModel.ad.creative.carouselCards = value.carouselCards!.map((o) => ({
        headline: o.headline!,
        description: o.description!,
        imageHash: o.imageHash!,
        imageUrl: o.imageUrl!,
        videoId: undefined,
        videoImage: undefined
      }));

      this.adCreationModel.ad.creative.callToAction = value.callToAction!;
      this.adCreationModel.ad.creative.destination = {
        displayUrl: value.destination?.displayUrl!,
        url: value.destination?.url!
      }!;
      this.adCreationModel.ad.creative.name = value.name!;
      this.adCreationModel.ad.name = value.name!;

      this.adCreationModel.ad.creative.identity.pageId = value.identity?.pageId! || this._adAccount?.pageId!;
      this.adCreationModel.ad.creative.identity.instagramAccountId = this._adAccount?.instagramAccountId!;
      this.adCreationModel.ad.creative.primaryText = value.primaryText!;
      this.facebookStateService.saveDraft$();
    });
  }

  listenModel() {
    this.facebookStateService.adCreationModel$
      .pipe(
        filter((o) => o.selectedTypes.ad === Facebook.AdType.CarouselAds),
        untilDestroyed(this)
      )
      .subscribe((o) => {
        const model = o as unknown as FacebookCreateAd.ICreateAdModel<Facebook.AdCarouselCreativeInput>;

        this.adCreationModel = model;

        if (model.adSet.promotedObject.customEventType) {
          this.callToActions = Facebook.decideCallToActionsByEvent(
            model.adSet.promotedObject.customEventType as Facebook.CustomEventType,
            Facebook.AdType.CarouselAds
          );
        } else if (o.campaign.objective === Facebook.CampaignObjective.OUTCOME_TRAFFIC) {
          this.callToActions = Facebook.getCallToActionsByObjectiveAndAdType(
            Facebook.CampaignObjective.OUTCOME_TRAFFIC,
            Facebook.AdType.CarouselAds
          );
        }

        this.arrangeCarouselCards(this.adCreationModel.ad.creative.carouselCards);

        this.form.patchValue({
          callToAction: model.ad.creative.callToAction,
          carouselCards: model.ad.creative.carouselCards,
          destination: model.ad.creative.destination,
          identity: model.ad.creative.identity,
          name: model.ad.name,
          primaryText: model.ad.creative.primaryText
        });
        this._images = this.adCreationModel.ad.creative.carouselCards
          .filter((o) => !!o.imageHash)
          .map((o) => ({
            id: o.imageHash!,
            url: o.imageUrl!
          }));
      });
  }
}
