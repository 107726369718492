import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OpenAI } from '@core/models';
import { OpenAiService } from '@core/services/open-ai.service';
import { LoaderState } from '@core/state/loader.state';
import { addHttpsPrefix, filterNotNil, patchWithHttps, URL_REGEX } from '@core/utils';
import { createOrderedStream } from '@core/utils/rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { TEXT_GENERATE_LANGUAGES, TEXT_GENERATE_TONES } from '@shared/constants';
import { INVALID_SCROLL_HANDLER } from '@shared/directives';
import { defaultInvalidScroll } from '@shared/handlers/default-scroll.handler';
import { AdModelService } from '@shared/services/ad-model.service';
import { TextAiContainerService } from '@shared/services/text-ai-container.service';
import { TypewriteService } from '@shared/services/typewrite.service';
import { BusinessService } from '@core/services';
import { BusinessState } from '@core/state/business.state';

type TextGeneratorProperties = Array<'description' | 'targetAudience' | 'language' | 'tone' | 'productName'>;

export function textGeneratorScrollHandler() {
  return (el: ElementRef<HTMLFormElement>) => {
    const invalidControlElements = el.nativeElement.querySelectorAll('[class*=invalid]');
    let offsetTop = Infinity;

    if (invalidControlElements.length) {
      offsetTop = invalidControlElements.item(0).clientTop;
    }

    if (offsetTop) {
      window.scrollTo({ top: offsetTop - 200 || el.nativeElement.offsetTop });

      return;
    }

    defaultInvalidScroll(el);
  };
}

@UntilDestroy()
@Component({
  selector: 'aayn-text-generator-ai',
  templateUrl: './text-generator-ai.component.html',
  providers: [
    TypewriteService,
    {
      provide: INVALID_SCROLL_HANDLER,
      useFactory: textGeneratorScrollHandler
    }
  ]
})
export class TextGeneratorAiComponent implements AfterViewInit {
  @Input() expanded: boolean = false;
  @Output() expandedChange = new EventEmitter<boolean>();

  textGeneratorForm = new FormGroup({
    productName: new FormControl<string>('', [Validators.required]),
    tone: new FormControl<OpenAI.TextTone | null>(null, [Validators.required]),
    language: new FormControl<string | null>(null, [Validators.required]),
    description: new FormControl<string>('', [Validators.required]),
    targetAudience: new FormControl<string>('', [Validators.required])
  });

  urlForm = new FormGroup({
    url: new FormControl<string>('', [Validators.required, Validators.pattern(URL_REGEX)])
  });

  chooseTones = TEXT_GENERATE_TONES;

  outputLanguages = TEXT_GENERATE_LANGUAGES;

  @Select(LoaderState.getAny(['GetProductDescription']))
  loader$!: Observable<boolean>;

  @Select(LoaderState.getAny('GenerateTexts'))
  fillLoader$!: Observable<boolean>;

  @Output()
  formSubmit = new EventEmitter<typeof this.textGeneratorForm.value>();

  @Output()
  urlSubmit = new EventEmitter<string>();

  private _subscription?: Subscription;

  constructor(
    private openAiService: OpenAiService,
    private typewriteService: TypewriteService,
    private textAiContainerService: TextAiContainerService,
    private adModelService: AdModelService,
    private businessService: BusinessService,
    private store: Store
  ) {}

  generatorSubmit(): void {
    if (this.textGeneratorForm.valid) {
      this.formSubmit.emit(this.textGeneratorForm.value);
    }
  }

  scan(properties: TextGeneratorProperties = ['description', 'targetAudience', 'language', 'tone', 'productName']) {
    const { url } = this.urlForm.value;

    if (url) {
      const parsed = addHttpsPrefix(url);
      this.urlSubmit.emit(parsed);
      const typeWriteProperties = properties.filter(
        (prop) => prop === 'description' || prop === 'targetAudience'
      ) as Array<'description' | 'targetAudience'>;

      this._subscription = this.openAiService
        .getProductDescription({ url: parsed })
        .pipe(filterNotNil)
        .subscribe((res) => {
          createOrderedStream(typeWriteProperties.map((prop) => this.typeWrite(prop, res[prop]))).subscribe(() => {
            let value = {};

            if (properties.includes('language')) {
              value = {
                language: this.outputLanguages.find((lang) => lang.label === res.language)?.value || ''
              };
            }

            if (properties.includes('productName')) {
              value = { ...value, productName: res?.name || '' };
            }

            if (properties.includes('tone')) {
              value = { ...value, tone: this.chooseTones.find((o) => o.value == OpenAI.TextTone.Professional)?.value };
            }

            this.textGeneratorForm.patchValue(value);
            this.textGeneratorForm.markAsTouched();
          });
        });
    }
  }

  cancelGetProductDescriptionCall() {
    this._subscription?.unsubscribe();
  }

  toggleButton() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

  ngAfterViewInit() {
    this.listenUrlChange();
    this.listenTextGeneratorForm();
    this.listenAdModel();
  }

  listenUrlChange() {
    const urlControl = this.urlForm.get('url') as FormControl;
    urlControl?.valueChanges
      .pipe(
        filter(() => urlControl?.invalid),
        debounceTime(100),
        untilDestroyed(this)
      )
      .subscribe((url) => {
        patchWithHttps(urlControl, url);
      });
  }

  listenTextGeneratorForm() {
    const stateService = this.adModelService.getStateService();

    this.textGeneratorForm.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged(), debounceTime(1000))
      .subscribe((value) => {
        this.textAiContainerService.setProductInfo({
          productName: value.productName!,
          productDescription: value.description!
        });
        if (stateService) {
          stateService.adCreationModel.textAi = {
            productName: value.productName,
            productDescription: value.description,
            targetAudience: value.targetAudience,
            tone: value.tone,
            language: value.language,
            url: this.urlForm.value.url
          };
          stateService.saveDraft$();
        }
      });
  }

  listenAdModel() {
    this.adModelService
      .getAdModel$()
      .pipe(untilDestroyed(this))
      .subscribe((adModel) => {
        if (adModel?.textAi && adModel.textAi.url) {
          this.textGeneratorForm.patchValue(
            { ...adModel.textAi, description: adModel.textAi.productDescription },
            { emitEvent: false }
          );
          this.urlForm.patchValue({ url: adModel.textAi.url }, { emitEvent: false });
          this.textAiContainerService.setProductInfo({
            productName: adModel.textAi.productName!,
            productDescription: adModel.textAi.productDescription!
          });
          this.textGeneratorForm.updateValueAndValidity();
        } else {
          const selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);
          if (selectedBusiness) {
            this.businessService.getBusinessAndBrand(selectedBusiness.id).subscribe((business) => {
              if (business.url) {
                this.urlForm.patchValue({ url: business.url }, { emitEvent: false });
                const scanProperties = ['targetAudience', 'language', 'tone', 'productName'] as TextGeneratorProperties;
                if (business.description) {
                  this.textGeneratorForm.patchValue({ description: business.description }, { emitEvent: false });
                } else {
                  scanProperties.push('description');
                }
                this.scan(scanProperties);
              }
            });
          }
        }
      });
  }

  typeWrite(field: keyof typeof this.textGeneratorForm.controls, value: any) {
    return this.typewriteService.typeWrite$(value, (val) => this.textGeneratorForm.patchValue({ [field]: val }), 1);
  }
}
