import { IOption } from '@ayn-ui/public-api';
import { BuildTuple } from '@core/utils/types';

import { IPageInfo } from '../base.model';
import { Base } from './base';

export module Bing {
  export type State = {
    funnelData: [];
  };

  export enum InsightInterval {
    Hour = 'Hour',
    Date = 'Date',
    Week = 'Week',
    Month = 'Month',
    Year = 'Year'
  }

  export enum BingIdResourceType {
    Campaign = 'Campaign',
    AdGroup = 'AdGroup',
    Ad = 'Ad'
  }

  export enum BingCurrency {
    ARS = 'ARS',
    AUD = 'AUD',
    THB = 'THB',
    VEF = 'VEF',
    BRL = 'BRL',
    CAD = 'CAD',
    CLP = 'CLP',
    COP = 'COP',
    DKK = 'DKK',
    EUR = 'EUR',
    HKD = 'HKD',
    INR = 'INR',
    MYR = 'MYR',
    MXN = 'MXN',
    TWD = 'TWD',
    NZD = 'NZD',
    NOK = 'NOK',
    PEN = 'PEN',
    PHP = 'PHP',
    PLN = 'PLN',
    IDR = 'IDR',
    SGD = 'SGD',
    ZAR = 'ZAR',
    SEK = 'SEK',
    CHF = 'CHF',
    TRY = 'TRY',
    GBP = 'GBP',
    USD = 'USD'
  }

  export enum BingGoogleImportFrequency {
    Auto = 'Auto',
    Now = 'Now',
    Once = 'Once',
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly'
  }
  export const BingGoogleImportFrequencyTypes = Object.values(BingGoogleImportFrequency);

  export enum BingCurrencyConstantType {
    MinimumBid = 'MinimumBid',
    MaximumBid = 'MaximumBid',
    MinimumDailyBudget = 'MinimumDailyBudget',
    MinimumMonthlyBudget = 'MinimumMonthlyBudget',
    MaximumMonthlyBudget = 'MaximumMonthlyBudget'
  }

  export enum BingAudienceTargetCriterion {
    Gender = 'Gender',
    Age = 'Age',
    Audience = 'Audience',
    CompanyName = 'CompanyName',
    JobFunction = 'JobFunction',
    Industry = 'Industry'
  }

  export enum BingAudienceType {
    RemarketingList = 'RemarketingList',
    Custom = 'Custom',
    InMarket = 'InMarket',
    Product = 'Product',
    SimilarRemarketingList = 'SimilarRemarketingList',
    CombinedList = 'CombinedList'
  }

  export enum BingProfileType {
    JobFunction = 'JobFunction',
    Industry = 'Industry'
  }

  export enum BingSupportedCampaignType {
    Audience = 'Audience',
    DynamicSearchAds = 'DynamicSearchAds',
    Search = 'Search',
    Shopping = 'Shopping'
  }

  export enum BingAdTypeResult {
    DynamicSearch = 'DynamicSearch',
    ResponsiveSearch = 'ResponsiveSearch',
    ExpandedText = 'ExpandedText'
  }

  export enum BingInsightStatus {
    Enabled = 'enabled',
    Paused = 'paused'
  }

  interface ITargetCriterionModel {
    label: string;
    value: BingAudienceTargetCriterion;
  }

  export const TARGET_CRITERION: ITargetCriterionModel[] = [
    { label: 'Gender', value: BingAudienceTargetCriterion.Gender },
    { label: 'Age', value: BingAudienceTargetCriterion.Age },
    { label: 'Audience', value: BingAudienceTargetCriterion.Audience },
    { label: 'Company Name', value: BingAudienceTargetCriterion.CompanyName },
    { label: 'Job Function', value: BingAudienceTargetCriterion.JobFunction },
    { label: 'Industry', value: BingAudienceTargetCriterion.Industry }
  ];

  export enum Gender {
    Men = 1,
    Women = 2
  }

  export enum CampaignObjective {
    ConversionsOnMyWebsite = 'ConversionsOnMyWebsite',
    TrafficToMyWebsite = 'TrafficToMyWebsite',
    ImportFromGoogle = 'ImportFromGoogle'
  }

  export enum AudienceType {
    Manual
  }

  export enum AdType {
    PerformanceMax
  }

  export module Business {
    export interface IOauthRequest {
      contextId: string;
    }

    export interface IOauthResponse {
      oauth: IBingOAuthOutput;
    }

    export interface IBingOAuthOutput {
      oauthUrl: string;
    }

    export interface IAdAccountResponse {
      adAccounts: IBingAdAccountOutput[];
    }

    export interface IAdAccountRequest {
      contextId: string;
    }

    export interface IBingAdAccountOutput {
      accessRole: BingAccessRole;
      adAccountId: number;
      loginCustomerId: number;
      name: string;
      email: string;
      status: BingAdAccountStatus;
      userStatus: BingUserAccountStatus;
    }

    export enum BingAccessRole {
      Writable = 'Writable',
      Readonly = 'Readonly'
    }

    export enum BingAdAccountStatus {
      Draft = 'Draft',
      Active = 'Active',
      Inactive = 'Inactive',
      Pause = 'Pause',
      Pending = 'Pending',
      Suspended = 'Suspended'
    }

    enum BingLanguage {
      Danish = 'Danish',
      Dutch = 'Dutch',
      English = 'English',
      Finnish = 'Finnish',
      French = 'French',
      German = 'German',
      Italian = 'Italian',
      Japanese = 'Japanese',
      Norwegian = 'Norwegian',
      Portuguese = 'Portuguese',
      Swedish = 'Swedish',
      Spanish = 'Spanish',
      Arabic = 'Arabic',
      Hebrew = 'Hebrew',
      Korean = 'Korean',
      Russian = 'Russian',
      TraditionalChinese = 'TraditionalChinese',
      SimplifiedChinese = 'SimplifiedChinese'
    }

    export enum BingUserAccountStatus {
      Active = 'Active',
      Inactive = 'Inactive'
    }

    export interface IConnectAdAccountRequest {
      contextId: string;
      adAccountId: string;
      loginId?: string;
    }

    export interface IBingCreatedAdAccountRequest {
      adAccountName: string;
      language: BingLanguage;
      currencyCode: string;
      businessAddress: BingBusinessAddress;
    }

    export interface IBingCreatedAdAccountReponse {
      createAdAccount: {
        adAccountId: string;
        loginCustomerId: string;
        directUrl: string;
      };
    }

    export interface BingBusinessAddress {
      businessName: string;
      city: string;
      countryCode: string;
      stateOrProvinceCode: string;
      postalCode: number;
      line1: string;
      line2: string;
      line3: string;
      line4: string;
    }

    export interface IConnectAdAccountResponse {
      connectAdAccount: IBingConnectedAdAccountOutput;
    }

    export interface IBingConnectedAdAccountOutput {
      businessId: string;
      connectedAdAccountId: string;
    }
  }

  export module Dashboard {
    export enum AccountOverviewType {
      Clicks = 'clicks',
      Impressions = 'impressions',
      SmartConversions = 'smartConversions',
      CustomConversions = 'customConversions',
      DestinationUrlConversions = 'destinationUrlConversions',
      OfflineConversions = 'offlineConversions',
      DurationConversions = 'durationConversions',
      AppInstalls = 'appInstalls',
      ViewPerVisit = 'viewPerVisit',
      InStoreTransactions = 'inStoreTransactions',
      StoreVisits = 'storeVisits',
      ProductConversions = 'productConversions',
      TimePeriod = 'timePeriod',
      Conversions = 'conversions',
      Ctr = 'ctr',
      Cost = 'cost',
      Revenue = 'revenue',
      CostPerClick = 'costPerClick'
    }

    export enum ICampaignStatus {
      ACTIVE = 'ACTIVE',
      PAUSED = 'PAUSED'
    }

    export const AccountOverviewTypes: IOption[] = [
      { label: 'Clicks', value: 'clicks' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'Click Rate', value: 'ctr' },
      { label: 'Conversions', value: 'conversions' },
      { label: 'SmartConversions', value: 'smartConversions' },
      { label: 'CustomConversions', value: 'customConversions' },
      { label: 'DestinationUrlConversions', value: 'destinationUrlConversions' },
      { label: 'OfflineConversions', value: 'offlineConversions' },
      { label: 'DurationConversions', value: 'durationConversions' },
      { label: 'AppInstalls', value: 'appInstalls' },
      { label: 'ViewPerVisit', value: 'viewPerVisit' },
      { label: 'InStoreTransactions', value: 'inStoreTransactions' },
      { label: 'StoreVisits', value: 'storeVisits' },
      { label: 'ProductConversions', value: 'productConversions' },
      { label: 'SmartConversions', value: 'smartConversions' }
    ];
    export interface IBreakdownRequest {
      startDate: number;
      endDate: number;
      datePreset: Base.Dashboard.Global.InsightDatePreset;
    }

    export type BreakdownType = keyof IAccountBreakdownResponse;

    export type IAccountBreakdownByBreakdownType<T extends BreakdownType> = IBaseBreakdown & {
      [K in T]: string;
    };

    export interface IAccountBreakdownResponse {
      age: Dashboard.IAccountBreakdownByBreakdownType<'age'>[];
      gender: Dashboard.IAccountBreakdownByBreakdownType<'gender'>[];
      deviceType: Dashboard.IAccountBreakdownByBreakdownType<'deviceType'>[];
      deviceOS: Dashboard.IAccountBreakdownByBreakdownType<'deviceOS'>[];
      network: Dashboard.IAccountBreakdownByBreakdownType<'network'>[];
      country: Dashboard.IAccountBreakdownByBreakdownType<'country'>[];
      state: Dashboard.IAccountBreakdownByBreakdownType<'state'>[];
      metroArea: Dashboard.IAccountBreakdownByBreakdownType<'metroArea'>[];
    }

    export interface IBaseBreakdown {
      identifier: string;
      conversions: number;
      costPerConversion: number;
      clicks: number;
      ctr: number;
      impressions: number;
      costPerClick: number;
      cost: number;
      revenue: number;
    }

    export interface IAdGroupsWithMetricsRequest {
      skip?: number;
      take?: number;
      campaignId: string;
      searchKey?: string;
      status: BingInsightStatus;
      startDate?: number;
      endDate?: number;
      datePreset: Base.Dashboard.Global.InsightDatePreset;
    }
    export interface IAdGroupsWithMetricsResponse {
      adGroupsWithMetrics: {
        edges: BingAdGroupSummaryOutputDtoEdge[];
        totalCount: number;
        pageInfo: IPageInfo;
      };
    }

    export interface BingAdGroupSummaryOutputDtoEdge {
      cursor: string;
      node: BingAdGroupSummaryOutputDto;
    }

    export interface BingAdGroupSummaryOutputDto {
      name: string;
      id: number;
      resourceName: string;
      status: string;
      startDate: string;
      endDate: string;
      clicks: number;
      impressions: number;
      costPerClick: number;
      conversions: number;
      costPerConversion: number;
      revenue: number;
      spend: number;
      ctr: number;
    }

    export interface IAdsWithMetricsRequest {
      skip?: number;
      take?: number;
      adGroupId: string;
      searchKey?: string;
      status: BingInsightStatus;
      startDate: number;
      endDate: number;
      datePreset: Base.Dashboard.Global.InsightDatePreset;
    }
    export interface IAdsWithMetricsResponse {
      adsWithMetrics: {
        edges: BingAdSummaryOutputDtoEdge[];
        totalCount: number;
        pageInfo: IPageInfo;
      };
    }
    export interface BingAdSummaryOutputDtoEdge {
      cursor: string;
      node: BingAdSummaryOutputDto;
    }
    export interface BingAdSummaryOutputDto {
      id: number;
      resourceName: string;
      status: string;
      clicks: number;
      impressions: number;
      ctr: number;
      costPerClick: number;
      conversions: number;
      costPerConversion: number;
      revenue: number;
      spend: number;
      adDetails: BingAdDetails;
    }

    export interface BingAdDetails {
      devicePreference: number;
      editorialStatus: string;
      finalUrlSuffix: string;
      finalUrls: string[];
      type: string;
      textAd: BingTextAd;
      productAd: BingPromotionRelatedAd;
      hotelAd: BingPromotionRelatedAd;
      appInstallAd: BingAppInstallAd;
      expandedTextAd: BingExpandedTextAd;
      dynamicSearchAd: BingDynamicSearchAd;
      responsiveAd: BingResponsiveAd;
      responsiveSearchAd: BingResponsiveSearchAd;
    }

    export interface BingTextAd {
      destinationUrl: string;
      displayUrl: string;
      text: string;
      title: string;
    }
    export interface BingPromotionRelatedAd {
      promotionalText: string;
    }

    export interface BingAppInstallAd {
      appPlatform: string;
      appStoreId: string;
      text: string;
      title: string;
    }
    export interface BingExpandedTextAd {
      domain: string;
      path1: string;
      path2: string;
      text: string;
      textPart2: string;
      titlePart1: string;
      titlePart2: string;
      titlePart3: string;
    }
    export interface BingDynamicSearchAd {
      path1: string;
      path2: string;
      text: string;
      textPart2: string;
    }
    export interface BingResponsiveAd {
      businessName: string;
      callToAction: string;
      callToActionLanguage: string;
      descriptions: string[];
      headline: string;
      headlines: string[];
      images: BingMediaDetails[];
      longHeadline: string;
      longHeadlineString: string;
      longHeadlines: String[];
      text: string;
      videos: BingMediaDetails[];
    }
    export interface BingMediaDetails {
      id: string;
      url: string;
    }
    export interface BingResponsiveSearchAd {
      descriptions: string[];
      domain: string;
      headlines: string[];
      path1: string;
      path2: string;
    }
    export interface ICampaignRequest {
      skip: number;
      take: number;
      searchKey: string;
      status: BingInsightStatus;
      startDate: number;
      endDate: number;
      datePreset: Base.Dashboard.Global.InsightDatePreset;
    }

    export interface ICampaignResponse {
      campaignsWithMetrics: {
        edges: [{ node: IBingCampaignOutput }];
        totalCount: number;
        pageInfo: { hasNextPage: boolean; hasPreviousPage: boolean; from: number; to: number };
      };
    }

    export interface SingleCampaign {
      dailyBudget?: number;
      id: string;
      name?: string;
      budgetId?: number;
      status?: string;
      timeZone?: string;
      goalIds?: string[] | '';
      campaignType?: string;
      budgetType?: string;
      languages?: string[];
      startDate?: string;
      endDate?: string;
    }

    export interface IBingCampaignOutput {
      isSmartCampaign: boolean;
      name: string;
      id: number;
      status: string;
      campaignType: string;
      startDate: string;
      endDate: string;
      dailyBudget: number;
      clicks: number;
      impressions: number;
      costPerClick: number;
      conversions: number;
      costPerConversion: number;
      revenue: number;
      spend: number;
      ctr: number;
      resourceName: string;
    }

    export interface IFunnelDashboardRequest {
      datePreset?: Base.Dashboard.Global.InsightDatePreset;
      startDate?: number;
      endDate?: number;
      interval?: InsightInterval;
    }
    export interface IFunnelDashboardResponse {
      funnelDashboard: IBingFunnelDashboardOutput[];
    }

    export interface IBingFunnelDashboardOutput {
      smartConversions: number;
      customConversions: number;
      destinationUrlConversions: number;
      offlineConversions: number;
      durationConversions: number;
      appInstalls: number;
      viewPerVisit: number;
      inStoreTransactions: number;
      storeVisits: number;
      productConversions: number;
      timePeriod: number;
      conversions: number;
      clicks: number;
      ctr: number;
      impressions: number;
    }

    export interface IBingAccountOverviewOutput {
      smartConversions: number;
      customConversions: number;
      destinationUrlConversions: number;
      offlineConversions: number;
      durationConversions: number;
      appInstalls: number;
      viewPerVisit: number;
      inStoreTransactions: number;
      storeVisits: number;
      productConversions: number;
      timePeriod: string;
      conversions: number;
      costPerConversion: number;
      clicks: number;
      ctr: number;
      impressions: number;
      costPerClick: number;
      cost: number;
      revenue: number;
    }

    export interface IAccountOverViewRequest {
      datePreset?: Base.Dashboard.Global.InsightDatePreset;
      startDate?: number;
      endDate?: number;
      interval?: InsightInterval;
    }

    export interface IAccountOverViewResponse {
      accountOverview: IBingAccountOverviewOutput[];
    }

    export interface ITurnResourceRequest {
      resourceNames: string[];
      resourceType: BingIdResourceType;
    }

    export interface IBingEditCampaignBudgetsRequest {
      campaignIds: string[];
      dailyBudgetAmount: number;
    }
    export interface IBingEditCampaignRequest {
      editableCampaigns: IEditableCampaigns[];
    }
    export interface IBingEditAdGroupRequest {
      campaignId: number;
      editableAdGroups: IEditableAdGroups[];
    }

    export interface IEditableAdGroups {
      id: string;
      name: string;
    }

    export interface IEditableCampaigns {
      id: string;
      name: string;
    }
  }

  export module AudienceCampaign {
    export interface AudienceCampaignRequest {
      audienceCampaign: AudienceCampaignRequestContent;
    }

    export interface AudienceCampaignRequestContent {
      name: string;
      dailyBudget?: number;
      paused: boolean;
      goalIds: number[];
      startDate: number;
      endDate?: number;
      assets: BingAudienceCampaignAssets;
      criteria: BingAudienceCampaignCriteria;
    }

    export interface BingAudienceCampaignAssets {
      businessName: string;
      finalUrl: string;
      descriptions: string[];
      headlines: string[];
      longHeadlines: string[];
      images: BingAudienceCampaignImages;
    }

    export interface BingAudienceCampaignImages {
      landscapeImageMedia: string[];
      squareImageMedia: string[];
      imageMedia15X10: string[];
      imageMedia178X100: string[];
      imageMedia133X100: string[];
    }

    export interface BingAudienceCampaignCriteria {
      narrowerTargetCriteria: BingAudienceTargetCriterion[];
      minAge: number;
      maxAge: number;
      male: boolean;
      female: boolean;
      target: BingCriteria;
      exclude: BingCriteria;
    }

    export interface BingCriteria {
      locationCriterionIds: Bing.AudienceCampaign.BingLocationConstant[];
      profileDataCriteria: BingProfileDataCriterion[];
      audienceCriterionIds: string[];
    }

    export interface BingProfileDataCriterion {
      id: string[];
      type: string[];
    }

    export interface ConversionGoalInput {
      id: number;
      conversionActionIds: Number[];
    }

    export interface LocationSearchRequest {
      limit: number;
      searchKey: string;
    }

    export interface BingGoogleCampaignImport {
      campaignImport: {
        googleAdAccountId: number;
        credentialId: string;
        selectedCampaigns: BingMultipleCampaignOption[];
        increase: boolean;
        budgetPercentage: number;
        syncFrequency: BingGoogleImportFrequency;
      };
    }

    export interface BingMultipleCampaignOption {
      campaignId: string;
      campaignName: string;
    }

    export interface LocationSearchResponse {
      locationSearch: BingLocationConstant[];
    }

    export interface BingLocationConstant {
      fullName: string;
      id: string;
      type: string;
    }

    export interface AudienceCriteriaSearchRequest {
      limit: number;
      searchKey: string;
      type: BingAudienceType;
      campaignType: BingSupportedCampaignType;
    }

    export interface ProfileDataSearchRequest {
      limit: number;
      searchKey: string;
      type: BingProfileType;
    }

    export interface AudienceCriteriaSearchResponse {
      audienceCriteriaSearch: BingAudienceCriterion[];
    }

    export interface ProfileDataSearchResponse {
      profileDataSearch: BingProfileConstant[];
    }

    export interface BingProfileConstant {
      fullName: string;
      id: string;
      type: string;
    }

    export interface BingAudienceCriterion {
      fullName: string;
      id: string;
      networkSize: number;
      searchSize: number;
      type?: string;
    }
    export interface BingDetailTargetingCriterion {
      fullName: string;
      id: string;
      searchSize?: number;
      type?: string;
    }

    export type UploadGroupTuple = [File, ...BuildTuple<4, File | null>];

    export interface IUploadPerformanceMaxImagesRequest {
      landscapeImageMedia?: UploadGroupTuple;
      squareImageMedia?: UploadGroupTuple;
      imageMedia15X10?: UploadGroupTuple;
      imageMedia178X100?: File;
      imageMedia133X100?: File;
    }

    export interface FileResponse {
      id: string;
      url: string;
    }
    export interface IUploadPerformanceMaxImagesResponse {
      landscapeImageMedia?: FileResponse[];
      squareImageMedia?: FileResponse[];
      imageMedia15X10?: FileResponse[];
      imageMedia178X100?: FileResponse[];
      imageMedia133X100?: FileResponse[];
    }

    export interface BingAccountImageAssetOutputDto {
      id: string;
      width: string;
      height: string;
      url: string;
      mediaType: string;
    }

    export interface GetCurrencyConstantValueRequest {
      currency: BingCurrency;
      type: BingCurrencyConstantType;
    }
    export interface GetCurrencyConstantValueResponse {
      getCurrencyConstantValue: number;
    }

    export interface IBingConversionGoalOutputReponse {
      getConversionGoals: IBingConversionGoalOutput[];
    }

    export interface IBingConversionGoalOutput {
      id: number;
      name: string;
    }
  }
}
