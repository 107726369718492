import { Base, Facebook, Google, IdWithUrl, OpenAI, Platforms } from '@core/models';
import { Nullable } from '@core/models/base.model';
import { InstantAdCreateAd } from '@core/ad-platforms/instant-ad';
import { contramap, reverse } from 'fp-ts/Ord';
import { concatAllWithEquality } from '@core/utils/ord';
import { pipe } from 'fp-ts/lib/function';
import { boolean, number } from 'fp-ts';

export module InstantAd {
  export module Client {
    export enum CampaignObjective {
      WebsiteTraffic = 'website-traffic'
    }

    export enum AudienceType {
      Manual = 'manual'
    }

    export enum AdType {
      Default = 'default'
    }

    export enum CampaignBudgetType {
      Daily = 'Daily',
      Total = 'Total'
    }

    export interface Audience {
      geoLocations: {
        inclusions: {
          [Platforms.Meta]?: Facebook.GeoLocationItem[];
          [Platforms.Google]?: Google.CreateAd.ILocationSearch[];
        };
        exclusions: {
          [Platforms.Meta]?: Facebook.GeoLocationItem[];
        };
      };
      detailedTargetings: {
        [Platforms.Meta]?: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem[];
        [Platforms.Google]?: Google.CreateAd.GooglePMInterestDetails[];
      };
    }

    export interface Campaign {
      objective: CampaignObjective;
      name: string;
      budget: number;
      budgetType: CampaignBudgetType;
      startDate: Date;
      endDate?: Date | null;
      appName: string;
    }

    export type TaskIdWithUrl = IdWithUrl<string> & { taskId: string };

    export interface Ad {
      shortHeadlines: GeneratedText[];
      headlines: GeneratedText[];
      descriptions: GeneratedText[];
      primaryTexts: GeneratedText[];

      selectedShortHeadlines: string[];
      selectedHeadlines: string[];
      selectedDescriptions: string[];
      selectedPrimaryTexts: string[];

      websiteUrl: string;
      images: string[];
      tasks: Task[];
      uploadedImages: Array<TaskIdWithUrl>;
    }

    export interface Task {
      conversionScore: number;
      taskId: string;
      textId: string;
    }

    export interface GeneratedText {
      text: string;
      id: string;
    }

    export interface TextAi extends Partial<Nullable<OpenAI.TextAIFormValue>> {
      customRequests?: string | null;
      cta?: string | null;
    }

    export interface AdCreativeCard {
      conversionScore: number;
      texts: {
        shortHeadline: string;
        description: string;
      };
      isMoreTextVisible: boolean;
      taskId: string;
      title: string;
      selected: boolean;
    }
  }

  export module Utils {
    export function makeMetaBudgetObject(from: InstantAdCreateAd.ICreateAdModel) {
      const isDaily = from.campaign.budgetType === InstantAd.Client.CampaignBudgetType.Daily;
      const propertyKey = isDaily ? 'dailyBudget' : 'lifeTimeBudget';
      return {
        [propertyKey]: String(from.campaign.budget)
      };
    }
    const conversionScoreOrder = pipe(
      number.Ord,
      contramap((it: { conversionScore: number }) => it.conversionScore)
    );
    const selectedOrder = pipe(
      boolean.Ord,
      contramap((it: { selected: boolean }) => it.selected)
    );

    export const cardOrder = concatAllWithEquality<Client.AdCreativeCard>((a, b) => a.taskId === b.taskId)([
      reverse(selectedOrder),
      reverse(conversionScoreOrder)
    ]);

    const nearestToOne = pipe(
      number.Ord,
      contramap((it: { aspectRatio: number }) => Math.abs(+it.aspectRatio - 1))
    );

    const smallestWidth = pipe(
      number.Ord,
      contramap((it: { width: string }) => +it.width)
    );

    export const scanImageOrder = concatAllWithEquality<Base.CreateAd.SearchImage & { aspectRatio: number }>(
      (a, b) => a.src === b.src
    )([nearestToOne, reverse(smallestWidth)]);
  }
}
