import { filter } from 'rxjs/operators';
import { notEmpty, notNil } from '@core/utils';

/**
 * Filter out values that are not undefined or null.
 */
export const filterNotNil = filter(notNil);

/**
 * Filter out values that are not undefined, null and empty iterables.
 */
export const filterNotEmpty = filter(notEmpty);
