import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BingService,
  FacebookService,
  GoogleService,
  LinkedinService,
  pascalCase,
  Platforms,
  SmartCampaignPlatform,
  TikTok,
  TiktokService,
  UserState
} from '@core/index';
import { Bing, Facebook, Google, Linkedin, PlatformKeyByValues } from '@core/models';
import { LoaderState } from '@core/state/loader.state';
import { environment } from '@environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { SmartCampaignStateService } from '@pages/create-ad/state/platforms/smart-campaign';
import { SmartResponseCatcherDirective } from '@shared/directives';
import { SystemTheme } from '@core/models/base.model';

type InterestType<T = any> = {
  name: string;
  platformsObject: T;
};

@UntilDestroy()
@Component({
  selector: 'aayn-detailed-targeting--add-more--smart-campaign',
  templateUrl: 'detailed-targeting-add-more.component.html'
})
export class DetailedTargetingAddMoreForSmartCampaignComponent implements OnInit {
  @Input() platform?: Platforms;

  @Input() form?: FormGroup;

  platforms = SmartCampaignPlatform;
  SystemTheme = SystemTheme;

  protected PlatformKeyByValues = PlatformKeyByValues;

  adCreationModel = this.smartCampaignStateService.adCreationModel;

  protected searchControl = new FormControl('', [
    (control) => (this.platform === Platforms.TikTok ? Validators.required(control) : null)
  ]);

  loading = false;

  interests?: InterestType[] = [];

  @Select(
    LoaderState.getAny([
      'LinkedinSearchByFacet',
      'InterestsSearch',
      'GetDetailedTargetings',
      'AudienceCriteriaSearch',
      'TikTokKeywordsSearch'
    ])
  )
  loader$?: Observable<boolean>;

  @Select(UserState.getSystemTheme) systemTheme$!: Observable<SystemTheme>;

  constructor(
    private smartCampaignStateService: SmartCampaignStateService,
    private cdr: ChangeDetectorRef,
    private linkedinService: LinkedinService,
    private googleService: GoogleService,
    private facebookService: FacebookService,
    private bingService: BingService,
    private tiktokService: TiktokService,
    @Optional() private smartResponseCatcherDirective?: SmartResponseCatcherDirective
  ) {}

  ngOnInit() {
    this.smartCampaignStateService.adCreationModel$.subscribe((o) => {
      this.adCreationModel = o;
    });
    this.listenSearchControl();
    this.listenInterestSearchLoader();
  }

  private listenSearchControl() {
    this.searchControl.valueChanges
      .pipe(
        filter((term): term is string => !!term),
        tap(() => (this.loading = true)),
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe((term) => {
        switch (this.platform) {
          case Platforms.Meta:
            this._getMetaInterest(term);
            break;
          case Platforms.Google:
            this._getGoogleInterest(term);
            break;
          case Platforms.Bing:
            this._getBingInterest(term);
            break;
          case Platforms.LinkedIn:
            this._getLinkedInterest(term);
            break;
          case Platforms.TikTok:
            this._getTikTokInterest(term);
            break;

          default:
            break;
        }
      });
  }

  private _getBingInterest(term: string) {
    this.bingService
      .audienceCriteriaSearchBing$({
        searchKey: term,
        type: 'InMarket',
        campaignType: Bing.BingSupportedCampaignType.Audience,
        limit: 10
      } as Bing.AudienceCampaign.AudienceCriteriaSearchRequest)
      .subscribe((result) => {
        try {
          this.interests = result?.map((l) => {
            l['name'] = l.fullName;
            return {
              name: l.fullName,
              platformsObject: l
            };
          });

          this.cdr.detectChanges();
        } catch (error) {
          !environment.production && console.log(`[CreateAd.Bing.DetailTargeting]: `, error);
        }
      });
  }

  private _getTikTokInterest(term: string) {
    this.tiktokService.interestSearch$({ searchKey: term }).subscribe((result) => {
      try {
        this.interests = result?.map((l) => {
          return {
            name: l.name,
            platformsObject: l
          };
        });
        this.cdr.detectChanges();
      } catch (error) {
        !environment.production && console.log(`[CreateAd.Tiktok.DetailTargeting]: `, error);
      }
    });
  }

  private _getGoogleInterest(term: string) {
    this.googleService
      .interestsSearch({ searchKey: term, limit: 10 } as Google.CreateAd.IGoogleInterestRequest)
      .subscribe((result) => {
        this.interests = result?.map((l) => {
          return {
            name: l.name,
            platformsObject: l
          };
        });

        this.cdr.detectChanges();
      });
  }

  private _getMetaInterest(term: string) {
    this.facebookService
      .getDetailedTargetings$(term!)
      .pipe(map((o) => o.data))
      .subscribe((result) => {
        this.interests = result?.detailedTargetings.map((l) => {
          return {
            name: l.name,
            platformsObject: l
          };
        });

        this.cdr.detectChanges();
      });
  }

  private _getLinkedInterest(term: string) {
    const facetMap = {
      company: pascalCase(Linkedin.FacetName.Employers),
      education: pascalCase(Linkedin.FacetName.Degrees),
      job: pascalCase(Linkedin.FacetName.TitlesAll),
      interest: pascalCase(Linkedin.FacetName.Interests)
    };
    this.linkedinService
      .searchByFacet({ facet: facetMap['interest'], omitLocale: true, query: term })
      .subscribe((o) => {
        this.interests = o.edges.map((l) => {
          return {
            name: l.node.name,
            platformsObject: l.node
          };
        });

        this.cdr.detectChanges();
      });
  }

  selectInterest(interest: InterestType) {
    let selectedInterest = this.getPlatformInterest(interest);

    if (selectedInterest) {
      this.adCreationModel.audience.detailedTargetings[this.platforms[Platforms[this.platform!]]] =
        this.adCreationModel.audience.detailedTargetings[this.platforms[Platforms[this.platform!]]].filter(
          (_interest) => _interest !== selectedInterest
        );
      return;
    }

    if (!this.adCreationModel.audience.detailedTargetings[this.platforms[Platforms[this.platform!]]])
      this.adCreationModel.audience.detailedTargetings[this.platforms[Platforms[this.platform!]]] = [];

    this.adCreationModel.audience.detailedTargetings[this.platforms[Platforms[this.platform!]]].push(
      interest.platformsObject
    );

    this._updateForm();
  }

  private _updateForm() {
    this.form?.patchValue({ detailedTargetings: this.adCreationModel.audience.detailedTargetings });

    this.smartResponseCatcherDirective?.processTabs();
  }

  getPlatformInterest(interest: InterestType) {
    switch (this.platform) {
      case Platforms.Meta:
      case Platforms.Google:
      case Platforms.Bing:
      case Platforms.TikTok:
        type HasInterestId = InterestType<
          | Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem
          | Google.CreateAd.IInterestsSearch
          | Bing.AudienceCampaign.BingLocationConstant
          | TikTok.CreateAd.Backend.TikTokLocationSearchOutputDto
        >;

        return this.adCreationModel.audience.detailedTargetings[this.platforms[Platforms[this.platform!]]]?.find(
          (o) => o.id == (interest as HasInterestId).platformsObject.id
        );

      case Platforms.LinkedIn:
        return this.adCreationModel.audience.detailedTargetings[this.platforms[Platforms[this.platform!]]]?.find(
          (o) => o.urn == (interest as InterestType<Linkedin.CreateAd.SearchByFacetEdgeNode>).platformsObject.urn
        );

      default:
        return undefined;
    }
  }

  _hasExistsInterest(interest: InterestType) {
    return !!this.getPlatformInterest(interest);
  }

  private listenInterestSearchLoader() {
    this.loader$?.pipe(untilDestroyed(this)).subscribe((loading) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });
  }
}
