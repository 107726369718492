import { AfterViewInit, ContentChild, ContentChildren, Directive, Inject, QueryList } from '@angular/core';
import { TextGeneratorAiComponent } from '@pages/create-ad/components/shared';
import { CreateAdInputComponent } from '@shared/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, pipe } from 'rxjs';
import { catchError, filter, finalize, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { OpenAI, Platforms } from '@core/models';
import { OpenAiService } from '@core/services';
import { Store } from '@ngxs/store';
import { createOrderedStream } from '@core/utils/rxjs';
import { cloneDeep } from 'lodash';
import { CreateAdState } from '@pages/create-ad/state';
import { runIsDev } from '@shared/utils';
import { InputDirective, TEXT_INPUT_LENGTH_CALCULATOR, TextInputLengthCalculator } from '@ayn-ui/public-api';
import { FileUploadAreaComponent } from '@ayn-ui/lib/modules/base/file-upload-area';

type GenerateTextsRequestSections = Pick<
  OpenAI.IGenerateTextsRequest,
  'headlines' | 'ctas' | 'descriptions' | 'punchlines'
>;

@UntilDestroy()
@Directive({
  selector: '[aaynTextAiContainer]'
})
export class TextAiContainerDirective implements AfterViewInit {
  @ContentChild(TextGeneratorAiComponent, { descendants: true }) textGeneratorAiComponent!: TextGeneratorAiComponent;

  @ContentChildren(FileUploadAreaComponent, { descendants: true })
  fileUploadAreaComponents?: QueryList<FileUploadAreaComponent>;

  @ContentChildren(CreateAdInputComponent, { descendants: true })
  createAdInputComponents!: QueryList<CreateAdInputComponent>;

  @ContentChild('urlInput', { read: InputDirective }) urlInput!: InputDirective;

  remainingAITexts: OpenAI.GeneratedTextsOutputDto = {};

  get createAdComponents() {
    return this.createAdInputComponents
      .toArray()
      .filter((component) => component.textAiSection && component.maxCharacterNumber);
  }

  get fillWithAiForm() {
    return this.textGeneratorAiComponent.textGeneratorForm.value;
  }

  constructor(
    private openAiService: OpenAiService,
    private store: Store,
    @Inject(TEXT_INPUT_LENGTH_CALCULATOR) private inputLengthCalculator: TextInputLengthCalculator
  ) {}

  ngAfterViewInit() {
    this.listenFillTextAi();

    this.arrangeTooltipOnToggle();

    if (this.urlInput) {
      this.textGeneratorAiComponent.urlSubmit.pipe(untilDestroyed(this)).subscribe((url) => {
        this.urlInput.setValue(url);
      });
    }
  }

  arrangeTooltipOnToggle() {
    this.fileUploadAreaComponents?.changes
      .pipe(startWith(this.fileUploadAreaComponents), untilDestroyed(this))
      .subscribe(() => {
        if (this.fileUploadAreaComponents) {
          this.fileUploadAreaComponents.toArray().forEach((fileUploadArea) => {
            fileUploadArea.tooltipDirectives.changes
              .pipe(
                startWith(fileUploadArea.tooltipDirectives),
                takeUntil(this.fileUploadAreaComponents!.changes),
                untilDestroyed(this)
              )
              .subscribe(() => {
                fileUploadArea.tooltipDirectives.toArray().forEach((tooltip) => {
                  tooltip.setPositionChangeTrigger(this.textGeneratorAiComponent.expandedChange.asObservable());
                });
              });
          });
        }
      });
  }

  getProductDescription() {
    return this.fillWithAiForm.description;
  }

  listenFillTextAi() {
    this.textGeneratorAiComponent.formSubmit
      .pipe(
        untilDestroyed(this),
        switchMap((form) => {
          const request = {
            outputLanguage: form.language!,
            description: form.description!,
            targetAudience: form.targetAudience!,
            ...this.generateTextAiSectionParameters(form.tone!)
          };
          runIsDev(() => {
            console.log('GenerateText.Request:', request);
          });
          return this.openAiService.getOpenAiGenerateTexts(request).pipe(catchError(() => of(undefined)));
        }),

        filter<OpenAI.GeneratedTextsOutputDto | undefined>((response) => !!response),
        map((response) => this.removeQuotes(response)),
        this.fillInputAndUpdateValue(() => this.createAdComponents)
      )
      .subscribe(() => {
        this.fillAddedInputs();
      });
  }

  generateTextAiSectionParameters(tone: OpenAI.TextTone): GenerateTextsRequestSections {
    const platform = this.store.selectSnapshot(CreateAdState.selectedPlatform);
    return this.createAdComponents.reduce((request, component) => {
      const section = component.textAiSection!;
      const index = request[section]?.findIndex((val) => val.length === component.maxCharacterNumber!);
      if (index !== undefined && index > -1) {
        ++request[section]![index].count;
      } else {
        request[section] = [
          ...(request[section] || []),
          {
            length: component.maxCharacterNumber!,
            count: 10,
            platform: Platforms[platform!],
            tone
          }
        ];
      }
      return request;
    }, {} as GenerateTextsRequestSections);
  }

  removeQuotes(response?: OpenAI.GeneratedTextsOutputDto) {
    return Object.entries(response || {}).reduce((acc, [key, value]) => {
      return {
        [key]: value.map((item) => item.replace(/\"/g, '')),
        ...acc
      };
    }, {} as OpenAI.GeneratedTextsOutputDto);
  }

  private fill(components: CreateAdInputComponent[], response?: OpenAI.GeneratedTextsOutputDto) {
    if (response) {
      const cloned = cloneDeep(response);
      const streams = components
        .map((component) => {
          const index = cloned[component.textAiSection!]!.findIndex(
            (val) => this.inputLengthCalculator(val) <= component.maxCharacterNumber!
          );
          if (index > -1) {
            return component.fill(cloned[component.textAiSection!]!.splice(index, 1)[0]);
          }
          return null;
        })
        .filter(Boolean);
      this.remainingAITexts = cloned;
      if (streams.length) {
        return createOrderedStream(streams as Array<Observable<string>>).pipe(
          finalize(() => {
            components.forEach((component) => {
              component.notifyValueChange();
            });
          })
        );
      }
    }
    return of(null);
  }

  private fillAddedInputs() {
    let currentInputs = [...this.createAdInputComponents];
    this.createAdInputComponents.changes
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          const newComponents = this.createAdComponents.filter((component) => !currentInputs.includes(component));
          if (newComponents.length) {
            return of(this.remainingAITexts).pipe(this.fillInputAndUpdateValue(() => newComponents));
          }
          return of(null);
        }),
        takeUntil(this.textGeneratorAiComponent.formSubmit)
      )
      .subscribe(() => {
        currentInputs = [...this.createAdInputComponents];
      });
  }

  private fillInputAndUpdateValue(getComponents: () => CreateAdInputComponent[]) {
    return pipe(
      switchMap((response: OpenAI.GeneratedTextsOutputDto | undefined) => this.fill(getComponents(), response))
    );
  }
}
