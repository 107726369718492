import { AfterViewInit, ChangeDetectorRef, Component, TrackByFunction } from '@angular/core';
import { InstantAdStateService } from '@pages/create-ad/state/platforms/instant-ad';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BusinessService } from '@core/services';
import { Store } from '@ngxs/store';
import { createObjectURL, filterNotEmpty } from '@core/utils';
import { IBusiness } from '@core/models';
import { BusinessState } from '@core/state/business.state';
import { InstantAdValidationService } from '@pages/create-ad/state/platforms/instant-ad/instant-ad.validation';
import { InstantAdCreateAd } from '@core/ad-platforms/instant-ad';
import { randomBetween } from '@core/utils/number';
import { AdcImageGenerationState } from '@pages/create-ad/state/adc-image-generation/adc-image-generation.state';
import { findLastIndex } from 'lodash';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ToastrService } from 'ngx-toastr';
import { InstantAd } from '@core/models/platforms/instant-ad';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'aayn-select-ai-generated--instant-ad',
  templateUrl: './select-ai-generated-content.component.html'
})
export class SelectAiGeneratedInstantAdComponent implements AfterViewInit {
  imageLoader$ = this.instantAdStateService.loader$;
  textLoader$ = this.instantAdStateService.textLoader$;
  adCreationModel = this.instantAdStateService.adCreationModel;

  cards: Array<InstantAd.Client.AdCreativeCard> = Array.from({ length: 30 }, () => ({
    conversionScore: 0,
    isMoreTextVisible: false,
    taskId: '',
    title: '',
    texts: {
      description: '',
      shortHeadline: ''
    },
    selected: false
  }));

  seeMoreClicked = false;

  sliceEnd = 9;

  businessLogo = '';

  business?: IBusiness | null;
  trackByFn: TrackByFunction<InstantAd.Client.AdCreativeCard> = (_: number, card: InstantAd.Client.AdCreativeCard) =>
    `${card.taskId}-${card.conversionScore}`;

  constructor(
    private cdr: ChangeDetectorRef,
    private instantAdStateService: InstantAdStateService,
    private store: Store,
    private businessService: BusinessService,
    private instantAdValidationService: InstantAdValidationService,
    private breakpointObserver: BreakpointObserver,
    private toastr: ToastrService
  ) {}

  ngAfterViewInit() {
    this.breakpointObserver
      .observe('(max-width: 1279.98px)')
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.sliceEnd = result.matches ? 10 : 9;
        this.cdr.detectChanges();
      });

    this.business = this.store.selectSnapshot(BusinessState.SelectedBusiness);
    if (this.business) {
      this.businessService.getBusinessLogo(this.business.id).subscribe((businessLogo) => {
        this.businessLogo = createObjectURL(businessLogo);
        this.cdr.detectChanges();
      });
    }

    this.instantAdStateService.adCreationModel$
      .pipe(
        filter((adCreationModel) => adCreationModel.ad.headlines.length > 0),
        untilDestroyed(this)
      )
      .subscribe((adCreationModel) => {
        this.updateCards(adCreationModel);

        this.cdr.detectChanges();
      });

    this.store
      .select(AdcImageGenerationState.tasks)
      .pipe(filterNotEmpty, untilDestroyed(this))
      .subscribe((tasks) => {
        this.cards
          .filter((card) => !card.conversionScore)
          .forEach((card) => {
            const task = tasks.find((t) => t.id === card.taskId);
            if (task?.status !== 5) return;

            const prevCardIndex = findLastIndex(this.cards, (card) => card.conversionScore !== 0);

            if (prevCardIndex === -1) {
              card!.conversionScore = 100;
            } else {
              const prevCard = this.cards[prevCardIndex];
              const maxValue = prevCard.conversionScore;
              const minValueIndex = [8, 16, 23, 30].findIndex((point) => prevCardIndex < point);
              const minValue = [98, 95, 93, 90][minValueIndex];
              card!.conversionScore = randomBetween(minValue, maxValue);
            }

            this.adCreationModel.ad.tasks.find((task) => task.taskId === card.taskId)!.conversionScore =
              card.conversionScore;
          });
        this.instantAdStateService.saveDraft$(false);
        this.sortCards();
      });
  }

  toggleMoreText(event: MouseEvent, card: { isMoreTextVisible: boolean }) {
    event.stopPropagation();

    card.isMoreTextVisible = !card.isMoreTextVisible;
    this.cdr.detectChanges();
  }

  selectCard(card: { selected: boolean }) {
    if (!card.selected) {
      const selectedCards = this.cards.filter((card) => card.selected);
      if (selectedCards.length === 10) {
        this.toastr.error('You can select up to 10 images');
        return;
      }
    }
    card.selected = !card.selected;
    this.instantAdValidationService.selectAiGeneratedAdValid$.next(this.cards.some((card) => card.selected));
    this.updateModel();
    this.cdr.detectChanges();
  }

  seeMoreClick() {
    this.sliceEnd = this.cards.length;
    this.seeMoreClicked = true;
  }

  updateModel(isSubmit = true) {
    const selectedCards = this.cards.filter((card) => card.selected);

    if (!selectedCards.length && isSubmit) {
      this.toastr.error('Please select at least one ad');

      return;
    }

    const headlines = new Set<string>();
    const shortHeadlines = new Set<string>();
    const descriptions = new Set<string>();
    const taskIds = new Set<string>();

    selectedCards.forEach((card) => {
      headlines.add(card.title);
      shortHeadlines.add(card.texts.shortHeadline);
      descriptions.add(card.texts.description);
      taskIds.add(card.taskId);
    });

    this.instantAdStateService.adCreationModel.ad.selectedHeadlines = Array.from(headlines);
    this.instantAdStateService.adCreationModel.ad.selectedShortHeadlines = Array.from(shortHeadlines);
    this.instantAdStateService.adCreationModel.ad.selectedDescriptions = Array.from(descriptions);
    this.instantAdStateService.adCreationModel.ad.images = Array.from(taskIds);
    this.instantAdStateService.saveDraft$(false);
  }

  private updateCards(adCreationModel: InstantAdCreateAd.ICreateAdModel) {
    if (!adCreationModel.ad.tasks.length) {
      this.setTextsBeforeImageGeneration(adCreationModel);
      return;
    }

    this.setCardTextsWithTasks(adCreationModel);
    this.instantAdValidationService.selectAiGeneratedAdValid$.next(this.cards.some((card) => card.selected));

    this.sortCards();
  }

  setTextsBeforeImageGeneration(adCreationModel: InstantAdCreateAd.ICreateAdModel) {
    this.cards.forEach((card, index) => {
      const textIndex = index % adCreationModel.ad.headlines.length;
      const textId = adCreationModel.ad.headlines[textIndex].id;
      card.title = this.getText('headlines', textId);
      card.texts.shortHeadline = this.getText('shortHeadlines', textId);
      card.texts.description = adCreationModel.ad.descriptions[0].text;
    });
  }

  setCardTextsWithTasks(adCreationModel: InstantAdCreateAd.ICreateAdModel) {
    this.cards = adCreationModel.ad.tasks.map((task) => {
      const headline = this.getText('headlines', task.textId);
      const shortHeadline = this.getText('shortHeadlines', task.textId);
      return {
        conversionScore: task.conversionScore,
        title: headline,
        texts: {
          shortHeadline,
          description: adCreationModel.ad.descriptions[0].text
        },
        isMoreTextVisible: false,
        taskId: task.taskId,
        selected: adCreationModel.ad.images.includes(task.taskId)
      };
    });
  }

  sortCards() {
    this.cards.sort(InstantAd.Utils.cardOrder.compare);
    this.cdr.detectChanges();
  }

  private getText = (section: 'headlines' | 'shortHeadlines' | 'descriptions', textId: string) => {
    return this.adCreationModel.ad[section].find((text) => text.id === textId)?.text || '';
  };
}
