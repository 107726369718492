<form #form="ngForm" (ngSubmit)="updateModel()">
  <section
    class="aayn-instant-ad--select-ai-generated"
    *ngIf="{ textLoader: textLoader$ | async, imageLoader: imageLoader$ | async } as data"
  >
    <ng-container *ngFor="let card of cards | slice: 0:sliceEnd; index as index; trackBy: trackByFn">
      <div
        class="aayn-instant-ad--select-ai-generated_card"
        [class.see-more-visible]="!!seeMoreClicked"
        [class.selected]="card.selected"
        (click)="card.taskId && selectCard(card)"
      >
        <div *ngIf="card?.selected" class="aayn-instant-ad--select-ai-generated_card_selected-content">
          <div class="selected-text">{{ 'Selected' | translate }}</div>
          <ayn-icon name="circle-filled-check"></ayn-icon>
        </div>

        <p
          class="aayn-instant-ad--select-ai-generated_card-conversion-score"
          wrapperClassName="aayn-instant-ad--select-ai-generated_card-conversion-score--loader"
          [ayn-skeleton-text]="!card.conversionScore || (imageTracker.loader$ | async)"
          [rowCount]="1"
        >
          <ayn-icon name="conversion-score"></ayn-icon>
          {{ 'Conversion Score:' | translate }}
          <span>
            <span class="fw-bold">{{ card.conversionScore }}</span
            >/ 100
          </span>
        </p>

        <div class="aayn-instant-ad--select-ai-generated_card-content">
          <div
            class="aayn-instant-ad--select-ai-generated_card-content_business"
            [class.active]="card.isMoreTextVisible"
          >
            <div class="aayn-instant-ad--select-ai-generated_card-content_business--img">
              <img
                [src]="businessLogo"
                [alt]="business?.name || ('[Business Name]' | translate)"
                (error)="businessLogo = ''"
                *ngIf="businessLogo.length"
              />
            </div>

            <span
              class="aayn-instant-ad--select-ai-generated_card-content_business--placeholder"
              *ngIf="!businessLogo?.length"
            >
              YOUR <br />
              LOGO</span
            >

            <div class="aayn-instant-ad--select-ai-generated_card-content_business--texts">
              <p class="aayn-instant-ad--select-ai-generated_card-content_business--title">
                {{ business?.name || ('[Business Name]' | translate) }}
              </p>
              <p class="aayn-instant-ad--select-ai-generated_card-content_business--description">
                {{ 'Sponsored' | translate }} <ayn-icon name="globe"></ayn-icon>
              </p>
            </div>
          </div>

          <div
            class="aayn-instant-ad--select-ai-generated_card-content_texts"
            [class.active]="card.isMoreTextVisible"
            [style.height.px]="child.clientHeight - (moreText.clientHeight ? 10 + moreText.clientHeight : 0)"
          >
            <div class="aayn-instant-ad--select-ai-generated_card-content_texts-content" #child>
              <p
                [ayn-skeleton-text]="data.textLoader"
                class="aayn-instant-ad--select-ai-generated_card-content_texts--visible"
              >
                {{ card.texts.shortHeadline }}
              </p>
              <div
                #moreText
                [ayn-skeleton]="data.textLoader"
                class="aayn-instant-ad--select-ai-generated_card-content_texts-content--more"
                [class.active]="card.isMoreTextVisible"
              >
                <p [ayn-skeleton]="data.textLoader">
                  {{ card.texts.description }}
                </p>
              </div>
              <a
                *ngIf="!data.textLoader"
                href="javascript:void(0)"
                (click)="toggleMoreText($event, card)"
                class="aayn-instant-ad--select-ai-generated_card-content_texts-content--see-more"
              >
                {{ (card.isMoreTextVisible ? 'Show Less' : 'See more') | translate }}
              </a>
            </div>
          </div>

          <div class="aayn-instant-ad--select-ai-generated_card-content_image">
            <img ayn-skeleton-img [ayn-adc-image-tracker]="card.taskId" #imageTracker="adcImageTracker" />
          </div>
          <p class="aayn-instant-ad--select-ai-generated_card-content_url">
            {{ adCreationModel.ad.websiteUrl }}
          </p>
          <p
            class="aayn-instant-ad--select-ai-generated_card-content_title"
            [ayn-skeleton-text]="data.textLoader"
            [rowCount]="2"
          >
            {{ card.title }}
          </p>
        </div>
      </div>
    </ng-container>

    <div class="aayn-instant-ad--select-ai-generated_see-more" *ngIf="!seeMoreClicked">
      <button ayn-button="white" type="button" (click)="seeMoreClick()">{{ 'See More' | translate }}</button>
    </div>
  </section>
</form>
