<section class="aayn-create-ad--item--container">
  <section class="aayn-create-ad--item--row">
    <aayn-select-ad-item
      *ngFor="let objective of objectives$ | async"
      [title]="objective.title"
      [active]="objective.active"
      [empty]="objective.empty"
      [description]="objective.description"
      [adsType]="objective.icon"
      (btnClick)="objectiveChangeStatus(objective)"
    >
    </aayn-select-ad-item>
  </section>
</section>

<div [ngSwitch]="selectedObjective">
  <div *ngSwitchCase="ObjectiveTypes.OUTCOME_TRAFFIC">
    <aayn-traffic-to-website--facebook></aayn-traffic-to-website--facebook>
  </div>

  <div *ngSwitchCase="ObjectiveTypes.AYN_CONVERSION">
    <aayn-conversion-my-website--facebook></aayn-conversion-my-website--facebook>
  </div>
</div>
