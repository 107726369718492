import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

/**
 * Returns an observable that emits the source value twice.
 * @param source {Observable<T>} The source observable.
 *
 * @example
 * const source = of(1, 2);
 * const example = source.pipe(duplicate());
 * example.subscribe(val => console.log(val));
 * // [1, 1]
 * // [2, 2]
 */
export function duplicate<T>(source: Observable<T>): Observable<[T, T]> {
  return source.pipe(map((value) => [value, value])) as Observable<[T, T]>;
}

/**
 * Returns an observable that emits the source value and the result of applying the mapper function to the source value.
 * @param predicate {(a: T) => R} The mapper function.
 *
 * @example
 * const source = of(1, 2);
 * const example = source.pipe(derive(x => x * 2));
 * example.subscribe(val => console.log(val));
 * // [1, 2]
 * // [2, 4]
 *
 */
export function derive<T, R>(predicate: (a: T, index: number) => R) {
  return function _derive(source: Observable<T>): Observable<[T, R]> {
    return source.pipe(map((value, index) => [value, predicate(value, index)])) as Observable<[T, R]>;
  };
}

/**
 * Finds the stream indices that satisfy the predicate.
 *
 * @param predicate {(a: T, index: number) => boolean} The filter predicate function.
 *
 * @example
 * const source = of(1, 2, 3, 4);
 * const example = source.pipe(findIndices(x => x % 2 === 0));
 * example.subscribe(val => console.log(val));
 * // 1
 * // 3
 */
export function findIndices<T>(predicate: (a: T, index: number) => boolean) {
  return function _findIndices(source: Observable<T>): Observable<number> {
    return source.pipe(
      derive((_, index) => index),
      filter(([it, index]) => predicate(it, index)),
      map(([_, index]) => index)
    );
  };
}

/**
 Returns an observable that emits the source's first element value if the predicate returns true.
 * @param predicate {(a: T) => boolean} The filter predicate function.
 *
 * @example
 * const source = of([1, 2], [3, 4]);
 * const example = source.pipe(filterFst(x => x === 3));
 * example.subscribe(val => console.log(val));
 * // [3, 4]
 *
 */
export function filterFst<T, S>(predicate: (first: T) => boolean) {
  return function _filterFst(source: Observable<[T, S]>): Observable<[T, S]> {
    return source.pipe(filter(([first, _]) => predicate(first))) as Observable<[T, S]>;
  };
}

/**
 * Returns an observable that emits the source's second element value if the predicate returns true.
 * @param predicate {(a: T) => boolean} The filter predicate function.
 *
 * @example
 * const source = of([1, 2], [3, 4]);
 * const example = source.pipe(filterSnd(x => x === 2));
 * example.subscribe(val => console.log(val));
 * // [1, 2]
 *
 */
export function filterSnd<F, T>(predicate: (second: T) => boolean) {
  return function _filterSnd(source: Observable<[F, T]>): Observable<[F, T]> {
    return source.pipe(filter(([_, second]) => predicate(second))) as Observable<[F, T]>;
  };
}

/**
 * Returns an observable that emits the source's first element value.
 * @param source {Observable<[T, _]>} The source observable.
 *
 * @example
 * const source = of([1, 2], [3, 4]);
 * const example = source.pipe(fst());
 * example.subscribe(val => console.log(val));
 * // 1
 * // 3
 */
export function fst<T, _>(source: Observable<[T, _]>): Observable<T> {
  return source.pipe(map(([first, _]) => first)) as Observable<T>;
}

/**
 * Returns an observable that emits the source's first element value.
 * @param source {Observable<[_ , T]>} The source observable.
 *
 * @example
 * const source = of([1, 2], [3, 4]);
 * const example = source.pipe(snd());
 * example.subscribe(val => console.log(val));
 * // 2
 * // 4
 */
export function snd<_, T>(source: Observable<[_, T]>): Observable<T> {
  return source.pipe(map(([_, second]) => second)) as Observable<T>;
}

/**
 * Returns an observable that emits the result of applying the mapper function to the source's first element value.
 * @param predicate {(a: T) => R} The mapper function.
 *
 * @example
 * const source = of([1, 2], [3, 4]);
 * const example = source.pipe(mapFst(x => x * 2));
 * example.subscribe(val => console.log(val));
 * // [2, 2]
 * // [6, 4]
 */
export function mapFst<T, R>(predicate: (a: T) => R) {
  return function _mapFst<S>(source: Observable<[T, S]>): Observable<[R, S]> {
    return source.pipe(map(([first, second]) => [predicate(first), second])) as Observable<[R, S]>;
  };
}

/**
 * Returns an observable that emits the result of applying the mapper function to the source's second element value.
 * @param predicate {(a: T) => R} The mapper function.
 *
 * @example
 * const source = of([1, 2], [3, 4]);
 * const example = source.pipe(mapSnd(x => x * 2));
 * example.subscribe(val => console.log(val));
 * // [1, 4]
 * // [3, 8]
 */
export function mapSnd<T, R>(predicate: (b: T) => R) {
  return function _mapSnd<F>(source: Observable<[F, T]>): Observable<[F, R]> {
    return source.pipe(map(([first, second]) => [first, predicate(second)])) as Observable<[F, R]>;
  };
}

/**
 * Returns an observable that emits the source's first element value to tap callback.
 * @param fn {(a: T) => void} The tap function.
 *
 * @example
 * const source = of([1, 2], [3, 4]);
 * const example = source.pipe(tapFst(console.log));
 * example.subscribe();
 * // 1, 3
 *
 */
export function tapFst<T, S>(fn: (first: T) => void): MonoTypeOperatorFunction<[T, S]> {
  return function _tapFst(source: Observable<[T, S]>): Observable<[T, S]> {
    return source.pipe(tap(([first, _]) => fn(first))) as Observable<[T, S]>;
  };
}

/**
 * Returns an observable that emits the source's second element value to tap callback.
 * @param fn {(a: T) => void} The tap  function.
 *
 * @example
 * const source = of([1, 2], [3, 4]);
 * const example = source.pipe(tapSnd(console.log));
 * example.subscribe();
 * // 2, 4
 *
 */
export function tapSnd<F, T>(fn: (second: T) => void): MonoTypeOperatorFunction<[F, T]> {
  return function _tapSnd(source: Observable<[F, T]>): Observable<[F, T]> {
    return source.pipe(tap(([_, second]) => fn(second))) as Observable<[F, T]>;
  };
}
