import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TEXT_GENERATE_LANGUAGES, TEXT_GENERATE_TONES } from '@shared/constants';
import {
  AynNgbModalRef,
  createUploadImages,
  IGetBusinessBrandResponse,
  OpenAI,
  PlatformStep,
  UploadImageItem
} from '@core/models';
import { IFileAddedEvent } from '@pages/create-ad/components/shared/file-upload-item/file-upload-item.component';
import { InstantAdStateService } from '@pages/create-ad/state/platforms/instant-ad';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { BusinessService, OpenAiService } from '@core/services';
import { Select, Store } from '@ngxs/store';
import { forkJoin, merge, Observable, of, race, Subject, Subscription } from 'rxjs';
import { InstantAdValidationService } from '@pages/create-ad/state/platforms/instant-ad/instant-ad.validation';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CollapseCardComponent, ErrorModalContent } from '@shared/components';
import { BusinessState } from '@core/state/business.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserState } from '@core/state';
import { catchError, filter, finalize, switchMap } from 'rxjs/operators';
import { AynService } from '@core/services/ayn.service';
import { blobToFile, createObjectURL, revokeObjectURL, scaleImageDown, toPng, URL_REGEX } from '@core/utils';
import { isEqual, omit, parseInt } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { LoaderState } from '@core/state/loader.state';
import { InstantAd } from '@core/models/platforms/instant-ad';

@UntilDestroy()
@Component({
  selector: 'aayn-describe-project--instant-ad',
  templateUrl: './describe-project-content.component.html'
})
export class DescribeProjectInstantAdComponent implements OnInit, OnDestroy {
  @Select(LoaderState.getAny(['GetProductDescription', 'GenerateTexts', 'GetImages']))
  loader$!: Observable<boolean>;

  @ViewChild('collapseCardComponent', { read: CollapseCardComponent }) collapseCardComponent!: CollapseCardComponent;

  form = this.fb.group({
    websiteUrl: this.fb.control('', { validators: [Validators.pattern(URL_REGEX)], nonNullable: true })
  });

  fileChanged = false;

  calculateTooltipPosition = new Subject();

  manualForm = this.fb.group(
    {
      targetAudience: this.fb.control('', { validators: Validators.required }),
      cta: this.fb.control('', { validators: Validators.required }),
      tone: this.fb.control('', { validators: Validators.required }),
      customRequests: this.fb.control(''),
      language: this.fb.control('', { validators: Validators.required }),
      productName: this.fb.control('', { validators: Validators.required }),
      productDescription: this.fb.control('', { validators: Validators.required })
    },
    {
      validators: (control) =>
        this.isFormChanged(control) && this.uploadedImages?.filter((image) => image.selected).length < 1
          ? { imageRequired: true }
          : null
    }
  );

  hoverIndex = -1;
  outputLanguages = TEXT_GENERATE_LANGUAGES;
  chooseTones = TEXT_GENERATE_TONES;
  isValid = true;
  uploadedImages: Array<{ isCustom: boolean; url: string; selected: boolean; file: File }> = [];
  images: UploadImageItem[] = createUploadImages(4);
  brand?: IGetBusinessBrandResponse;
  businessLogo?: File;
  subscription?: Subscription;

  get selectedImages() {
    return this.uploadedImages.filter((image) => image.selected);
  }

  get firstUploadedIndex() {
    return this.uploadedImages.findIndex((image) => image.url);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private instantAdStateService: InstantAdStateService,
    private fb: FormBuilder,
    private openAiService: OpenAiService,
    private store: Store,
    private instantAdValidationService: InstantAdValidationService,
    private businessService: BusinessService,
    private modalService: NgbModal,
    private router: Router,
    private aynService: AynService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.instantAdStateService.adCreationModel$.subscribe((model) => {
      this.manualForm.patchValue({
        cta: model.textAi.cta,
        targetAudience: model.textAi.targetAudience,
        tone: model.textAi.tone,
        productName: model.textAi.productName,
        productDescription: model.textAi.productDescription,
        customRequests: model.textAi.customRequests,
        language: model.textAi.language
      });
      this.form.patchValue({
        websiteUrl: model.ad.websiteUrl
      });
    });

    this.instantAdValidationService.registerForm(this.manualForm, PlatformStep.DescribeProject, this);

    this.getBrand();
  }

  onFileAdded(event: IFileAddedEvent) {
    const image = this.images.find((image) => !image.startedUploading && !image.completed)!;
    if (!image) {
      return;
    }
    image.complete();
    this.fileChanged = true;

    const uploadImageIndex = this.images.findIndex((i) => i === image);
    this.uploadedImages[uploadImageIndex] = {
      url: event.fileUrl!,
      selected: true,
      isCustom: true,
      file: event.file
    };
  }

  remove(event: MouseEvent, index: number) {
    event.stopPropagation();
    event.preventDefault();
    if (index > -1) {
      this.fileChanged = true;
      this.uploadedImages = this.uploadedImages.filter((_, i) => i !== index);
      this.images.forEach((_, i) => {
        if (i == index || i > index) {
          if (i !== this.images.length - 1 && this.images[i + 1].startedUploading) {
            this.images[i] = this.images[i + 1];
            this.images[i + 1] = new UploadImageItem();
          } else {
            this.images[i] = new UploadImageItem();
          }
        }
      });
    }
    this.cdr.detectChanges();
    this.calculateTooltipPosition.next();
  }

  selectFile(index: number) {
    if (this.images[index].completed) {
      this.fileChanged = true;
      this.uploadedImages[index].selected = !this.uploadedImages[index].selected;
    }
  }

  isFormChanged(form?: AbstractControl) {
    const value = form?.value || this.manualForm.value;
    return (
      this.fileChanged ||
      !isEqual(omit(this.instantAdStateService.adCreationModel.textAi, ['url']), value) ||
      this.form.controls.websiteUrl.value !== this.instantAdStateService.adCreationModel.ad.websiteUrl
    );
  }

  scan() {
    if (this.subscription && !this.subscription.closed) {
      return;
    }
    const url = this.form.controls.websiteUrl.value;

    this.subscription = forkJoin([
      this.openAiService.getProductDescription({ url }),
      this.aynService.searchImages({ url })
    ]).subscribe(([productDesc, images]) => {
      this.fileChanged = true;

      this.uploadedImages.forEach((image, index) => {
        if (image.isCustom) return;
        revokeObjectURL(image.url);
        image.url = '';
        image.selected = false;
        this.images[index] = new UploadImageItem();
      });
      const sortedImages = images
        .map((image) => ({ ...image, aspectRatio: parseInt(image.width) / parseInt(image.height) }))
        .sort(InstantAd.Utils.scanImageOrder.compare);

      const willFillImagesLength = this.images.filter((i) => !i.completed).length;

      const selectedImages = sortedImages
        .filter((image) => +(image.width || 0) > 650 && +(image.height || 0) !== 0)
        .splice(0, willFillImagesLength)
        .map((image) => image.src);

      if (!selectedImages.length) {
        this.toastr.error('We can not find images on your website. Please add at least 4 images', 'Error');
        this.collapseCardComponent.open();
      }

      merge(
        ...selectedImages.map((url) =>
          this.aynService.getImage(url).pipe(
            switchMap((image) => scaleImageDown(image, { maxWidth: 1024, maxHeight: 1024 })),
            switchMap(toPng)
          )
        )
      )
        .pipe(
          finalize(() => {
            this.collapseCardComponent.open();
          })
        )
        .subscribe((image) => {
          const imageIndex = this.images.findIndex((i) => !i.completed);

          this.images[imageIndex].complete();
          this.uploadedImages[imageIndex] = {
            isCustom: false,
            selected: true,
            url: createObjectURL(image),
            file: blobToFile(image, `image-${imageIndex}`)
          };
          this.cdr.detectChanges();
        });

      if (productDesc) {
        this.manualForm.patchValue({
          cta: productDesc.cta,
          targetAudience: productDesc.targetAudience,
          tone: OpenAI.TextTone.Professional,
          productName: productDesc.name,
          productDescription: productDesc.description,
          language: this.outputLanguages.find((lang) => lang.label === productDesc.language)?.value || ''
        });
      }
    });
  }

  getBrand() {
    const selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);
    if (!selectedBusiness) {
      return;
    }

    forkJoin([
      this.businessService.getBrandById(selectedBusiness.id).pipe(catchError(() => of(null))),
      this.businessService.getBusinessLogo(selectedBusiness.id).pipe(
        switchMap(toPng),
        catchError(() => of(null))
      )
    ]).subscribe(([brand, logo]) => {
      if (!brand?.data || !logo) {
        this.openBusinessUpdateModal();
      } else {
        this.brand = brand.data;
        this.businessLogo = blobToFile(logo, 'logo');
      }
    });
  }

  openBusinessUpdateModal() {
    const selectedBusiness = this.store.selectSnapshot(BusinessState.SelectedBusiness);
    if (!selectedBusiness) {
      return;
    }

    const modalRef = this.modalService.open(ErrorModalContent, {
      backdrop: 'static',
      keyboard: false
    }) as AynNgbModalRef<ErrorModalContent>;
    const title = 'Business Update Required';
    let subContent =
      'You need to update your business to continue. Please click the button below to update your business.';
    let confirmButtonText = 'Update Business';
    const isSubAccount = this.store.selectSnapshot(UserState.isInviterUser);

    if (isSubAccount) {
      subContent = 'You need to update the business to continue. Please ask the owner to update the business.';
      confirmButtonText = '';
    } else {
      modalRef.componentInstance.confirmButton.subscribe(() => {
        this.router.navigate(['/main/business/update', selectedBusiness.id]);
      });
    }

    race(modalRef.componentInstance.cancelButton, modalRef.closed.pipe(filter((result) => !result))).subscribe(() => {
      this.router.navigate(['/main/create-ad']);
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.subContent = subContent;
    modalRef.componentInstance.confirmButtonText = confirmButtonText;
    modalRef.componentInstance.cancelButtonText = 'Return to create ad';
  }

  cancelGetProductDescriptionCall() {
    this.subscription?.unsubscribe();
  }

  onSubmit() {
    this.manualForm.updateValueAndValidity();
    if (!this.manualForm.valid) {
      this.collapseCardComponent.open();
      return;
    }
    const url = this.form.controls.websiteUrl.value;
    if (!this.isFormChanged()) {
      return;
    }
    const value = this.manualForm.value;
    this.instantAdStateService.adCreationModel.textAi = value;

    this.instantAdStateService.adCreationModel.ad.websiteUrl = url;
    this.instantAdStateService.generateAdTexts(
      {
        description: value.productDescription!,
        outputLanguage: value.language!,
        targetAudience: value.targetAudience!,
        customRequests: value.customRequests!,
        productName: value.productName!,
        tone: value.tone!
      },
      {
        color1Hex: this.brand!.color1,
        color2Hex: this.brand!.color2,
        brandImage: this.businessLogo!,
        backgroundImages: this.selectedImages.map((image) => image.file)
      }
    );
    this.revokeImageUrls();
  }

  revokeImageUrls() {
    this.uploadedImages.filter((image) => image.url).forEach((image) => revokeObjectURL(image.url));
  }

  isEmptyImageInvalid(index: number) {
    const image = this.images[index];
    return index === 0 && !image.completed && this.selectedImages.length < 1 && this.isFormChanged();
  }

  isFilledImageInvalid(index: number) {
    const image = this.images[index];
    const uploadImageItem = this.uploadedImages[index];
    return (
      image.completed &&
      !uploadImageItem?.selected &&
      index === this.firstUploadedIndex &&
      this.selectedImages.length < 1 &&
      this.isFormChanged()
    );
  }

  ngOnDestroy() {
    this.revokeImageUrls();
    this.subscription?.unsubscribe();
  }
}
