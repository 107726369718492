import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';
import { delayWhen, map, retryWhen, scan, switchMap, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Query from '@core/queries';

import {
  Base,
  Bing,
  calculateState,
  captureSentryException,
  Facebook,
  GraphQLService,
  Linkedin,
  LinkedinService,
  Platforms,
  SmartCampaign,
  SmartCampaignPlatform,
  Upload
} from '../';
import { notifySmartCampaignErrors } from '../utils/notify-smart-campaign-pipe';
import { of, pipe, throwError, timer } from 'rxjs';
import { LinkedInSingleImageStatusEnum } from '@pages/create-ad/components/select-adtype/platforms/linkedin/components/modal';
import { omit } from 'lodash';

@Injectable({ providedIn: 'root' })
export class SmartCampaignService {
  constructor(
    private gql: GraphQLService,
    private httpClient: HttpClient,
    private toastrService: ToastrService,
    private linkedinService: LinkedinService
  ) {}

  callToActions$(selectedPlatforms: SmartCampaignPlatform[]) {
    return this.gql
      .get(Query.SmartCampaignQ.createAd.callToActions(selectedPlatforms)())
      .pipe(notifySmartCampaignErrors(this.toastrService));
  }

  getLanguages$(selectedPlatforms: Platforms[]) {
    return this.gql
      .get(Query.SmartCampaignQ.createAd.languages(selectedPlatforms)())
      .pipe(notifySmartCampaignErrors(this.toastrService));
  }

  getInterest$(selectedPlatforms: Platforms[], params: SmartCampaign.Backend.SmartCampaignInterestGenerateRequestDto) {
    return this.gql
      .get(Query.SmartCampaignQ.createAd.getInterest(selectedPlatforms)({ params }))
      .pipe(notifySmartCampaignErrors(this.toastrService));
  }

  getSeperatedLocations$(selectedPlatforms: SmartCampaignPlatform[], params: string) {
    return this.gql.get(Query.SmartCampaignQ.createAd.getSeperatedLocations(selectedPlatforms, params)());
  }

  getSmartLocations$(selectedPlatforms: Platforms[], params: SmartCampaign.Backend.GetSmartLocationRequest) {
    return this.gql
      .get(Query.SmartCampaignQ.createAd.getSmartLocations(selectedPlatforms)({ params }))
      .pipe(notifySmartCampaignErrors(this.toastrService));
  }

  getImageConfig(selectedPlatforms: SmartCampaignPlatform[]) {
    return this.gql.get(Query.SmartCampaignQ.createAd.getImageConfig(selectedPlatforms)()).pipe(
      map((result) => result.data),
      map((o) => {
        o.imageConfig.configs.forEach((ic) => {
          ic.common.dimensions.min.width = Math.ceil(ic.common.dimensions.min.width);
          ic.common.dimensions.min.height = Math.ceil(ic.common.dimensions.min.height);
        });

        return o;
      })
    );
  }

  uploadImage$(formData: FormData, uploadImageDetails: SmartCampaign.Backend.UploadImageDetails) {
    const initialState: Upload<SmartCampaign.Backend.FileUploadResponse> = { state: 'PENDING', progress: 0 };
    formData.append('details', JSON.stringify(uploadImageDetails));
    return this.httpClient
      .post<any>(`${environment.apiUrl}/ayn/upload-image`, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        scan(calculateState, initialState),
        this.getLinkedinImage(),
        this.sentErrorsToSentry(formData, uploadImageDetails)
      );
  }

  validateSmartCampaign(campaign: SmartCampaign.Backend.SmartCampaignCreateDto) {
    return this.gql
      .get(Query.SmartCampaignQ.createAd.validateSmartCampaign({ campaign }))
      .pipe(notifySmartCampaignErrors(this.toastrService));
  }

  createSmartCampaign(selectedPlatforms: Platforms[], campaign: SmartCampaign.Backend.SmartCampaignCreateDto) {
    return this.gql
      .post(Query.SmartCampaignQ.createAd.createSmartCampaign(selectedPlatforms)({ campaign }))
      .pipe(notifySmartCampaignErrors(this.toastrService));
  }

  geSmartCampaignsBing$(payload: Bing.Dashboard.ICampaignRequest) {
    return this.gql
      .post(Query.SmartCampaignQ.dashboard.bing.getCampaigns(payload))
      .pipe(map((result) => result.data?.bing));
  }

  geSmartCampaignsFacebook$(payload: Base.Dashboard.Global.IStartEndDateRequest) {
    const body = {
      dateRange: {
        start: payload.start,
        end: payload.end
      },
      after: payload.after,
      before: payload.before,
      first: payload.first,
      last: payload.last,
      name: payload.name
    } as Facebook.Dashboard.GetCampaignsRequest;

    return this.gql.get(Query.SmartCampaignQ.dashboard.facebook.getCampaigns(body)).pipe(map((r) => r.data.facebook));
  }

  geSmartCampaignsLinkedin$(payload: Linkedin.Dashboard.ICampaignRequest) {
    return this.gql
      .post(Query.SmartCampaignQ.dashboard.linkedin.getCampaigns(payload))
      .pipe(map((result) => result.data?.linkedin));
  }

  private getLinkedinImage() {
    return pipe(
      switchMap((upload: Upload<SmartCampaign.Backend.FileUploadResponse>) => {
        if (upload.state !== 'DONE' || !upload.result?.results.linkedin) return of(upload);
        const body = upload.result as SmartCampaign.Backend.FileUploadResponse;

        return this.linkedinService.getSingleImageDetail(body.results.linkedin['image']).pipe(
          map((singleImageDetail) => {
            switch (singleImageDetail?.status) {
              case LinkedInSingleImageStatusEnum.PROCESSING:
              case LinkedInSingleImageStatusEnum.WAITING_UPLOAD:
                throw { error: { message: ['WAIT_AVAILABLE'] } };

              case LinkedInSingleImageStatusEnum.PROCESSING_FAILED:
                body.results = omit(body.results, 'linkedin') as SmartCampaign.Backend.FileUploadResponse['results'];
                body.failures.linkedIn = ['Can not Upload Image'];
                break;
              case LinkedInSingleImageStatusEnum.AVAILABLE:
                body.results.linkedin.uploadUrl = singleImageDetail?.downloadUrl || '';
                break;
            }
            return upload;
          }),
          retryWhen((errors) => {
            return errors.pipe(
              delayWhen((error) =>
                error?.error?.message?.includes('WAIT_AVAILABLE') ? timer(3000) : throwError(error)
              )
            );
          })
        );
      })
    );
  }

  private sentErrorsToSentry(formData: FormData, body: SmartCampaign.Backend.UploadImageDetails) {
    return pipe(
      tap(async (upload: Upload<SmartCampaign.Backend.FileUploadResponse>) => {
        try {
          if (upload.state !== 'DONE') return;
          const hasFailure = Object.values(upload.result?.failures || {}).flatMap((o) => o).length;
          if (!hasFailure) return;
          const sentryError = new Error();
          sentryError.name = '💥🧠 Smart Campaign File Upload Failure';
          const file = formData.get('image') as File;
          const buffer = await file.arrayBuffer();
          const data = new Uint8Array(buffer);
          captureSentryException(sentryError, {
            attachments: [
              {
                data,
                filename: file.name || `image-sentry-${Date.now()}.${file.type.split('/')[1]}`
              }
            ],
            captureContext: {
              extra: {
                body: JSON.stringify(body),
                response: JSON.stringify(upload.result)
              }
            }
          });
        } catch (err) {
          console.error(err);
        }
      })
    );
  }

  fetchPlatformBudgets$(smartCampaignId: string) {
    return this.gql
      .get(Query.SmartCampaignQ.dashboard.fetchPlatformBudgetsQuery({ smartCampaignId }))
      .pipe(map((result) => result.data.fetchPlatformBudgets));
  }

  fetchSmartCampaignId$(platform: string, platformCampaignId: string) {
    return this.gql
      .get(Query.SmartCampaignQ.dashboard.fetchSmartCampaignIdQuery({ platform, platformCampaignId }))
      .pipe(map((result) => result.data?.fetchSmartCampaignId));
  }

  fetchTotalBudget$(smartCampaignId: string) {
    return this.gql
      .get(Query.SmartCampaignQ.dashboard.fetchTotalBudgetQuery({ smartCampaignId }))
      .pipe(map((result) => result.data.fetchTotalBudget));
  }

  isSmartBudgetActive$(smartCampaignId: string) {
    return this.gql
      .get(Query.SmartCampaignQ.dashboard.isSmartBudgetActiveQuery({ smartCampaignId }))
      .pipe(map((result) => result.data.isSmartBudgetActive));
  }

  editPlatformCampaignBudgets$(budgets: SmartCampaign.Backend.EditPlatformCampaignBudgetsInput) {
    return this.gql
      .post(Query.SmartCampaignQ.dashboard.editPlatformCampaignBudgets(budgets))
      .pipe(map((res) => res.data?.editPlatformCampaignBudgets));
  }

  editSmartTotalBudget$(totalBudget: Required<SmartCampaign.Backend.EditSmartBudgetDto>) {
    return this.gql
      .post(Query.SmartCampaignQ.dashboard.editSmartTotalBudgetQuery(totalBudget))
      .pipe(map((res) => res.data?.editSmartTotalBudget));
  }

  editSmartStatus$(input: SmartCampaign.Backend.EditSmartCampaignStatusInput) {
    return this.gql
      .post(Query.SmartCampaignQ.dashboard.editStatus(input))
      .pipe(map((res) => res.data?.editSmartSingleCampaignStatuses));
  }
}
