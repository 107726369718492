import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AuthService, createObjectURL, resizeImage, Settings, SettingsService, toIco, UserLoginResponse } from '..';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SystemTheme } from '@core/models/base.model';

/**
 * Models
 */
export namespace User {
  export interface State {
    user: UserLoginResponse;
    theme: Settings.BrandingOutput;
    logo: string;
    icon: string;
    systemTheme: SystemTheme;
  }
}

/**
 * Actions
 */
export class SetUser {
  static readonly type = '[User] Get User';
  static readonly desc = 'getting user';

  constructor(public payload: UserLoginResponse) {}
}

export class SetUserTheme {
  static readonly type = '[SetUserTheme] Get Theme';
  static readonly desc = 'getting theme';

  constructor() {}
}

export class SetSystemTheme {
  static readonly type = '[SetSystemTheme] Set system Theme';
  static readonly desc = 'getting system theme';

  constructor(public payload: SystemTheme) {}
}

export class GetSystemTheme {
  static readonly type = '[GetSystemTheme] Get system Theme';
  static readonly desc = 'getting system theme';

  constructor() {}
}

export class SetCompanyLogo {
  static readonly type = '[SetCompanyLogo] Get company logo';
  static readonly desc = 'getting company logo';

  constructor() {}
}

/**
 * Defaults
 */
export const USER_DEFAULTS: User.State = {
  user: {} as UserLoginResponse,
  theme: {} as Settings.BrandingOutput,
  logo: '',
  icon: '',
  systemTheme: SystemTheme.LIGHT
};

@State<User.State>({
  name: 'UserState',
  defaults: USER_DEFAULTS
})
@Injectable()
export class UserState {
  constructor(private settingsService: SettingsService, private authService: AuthService) {}

  @Selector()
  static user({ user }: User.State): UserLoginResponse {
    return user;
  }

  @Selector()
  static isInviterUser({ user }: User.State): boolean {
    return !!user?.user.inviter?.inviterId;
  }

  @Selector()
  static getUserTheme({ theme }: User.State): Settings.BrandingOutput {
    return theme;
  }

  @Selector()
  static isUserTheme({ theme }: User.State): boolean {
    return !!theme;
  }

  @Selector()
  static getCompanyLogo({ logo }: User.State): string | undefined {
    return logo;
  }

  @Selector()
  static getIcon({ icon }: User.State): string | undefined {
    return icon;
  }

  @Selector()
  static getSystemTheme({ systemTheme }: User.State): string | undefined {
    return systemTheme;
  }

  @Action(SetUser)
  userLogin({ patchState }: StateContext<User.State>, { payload }: SetUser) {
    patchState({ user: payload });
  }

  @Action(SetUserTheme)
  userTheme({ patchState }: StateContext<User.State>) {
    return this.settingsService.getCustomBranding().pipe(
      tap((result) => {
        patchState({ theme: result.data });
      })
    );
  }

  @Action(SetSystemTheme)
  systemTheme({ patchState }: StateContext<User.State>, { payload }: SetSystemTheme) {
    return this.authService.setTheme(payload).pipe(
      tap((result) => {
        patchState({ systemTheme: result.data || SystemTheme.LIGHT });
      })
    );
  }

  @Action(GetSystemTheme)
  getSystemTheme({ patchState }: StateContext<User.State>) {
    return this.authService.getTheme().pipe(
      tap((result) => {
        patchState({ systemTheme: result.data || SystemTheme.LIGHT });
      })
    );
  }

  @Action(SetCompanyLogo)
  setCompanyLogo({ patchState }: StateContext<User.State>) {
    return this.settingsService.getCompanyLogo().pipe(
      catchError(() => {
        patchState({ logo: '' });
        return of(null);
      }),
      switchMap((blob) => {
        if (blob) {
          return resizeImage(blob, 195, 195).pipe(
            switchMap(toIco),
            map((icon) => ({ logo: createObjectURL(blob), icon: createObjectURL(icon) }))
          );
        }
        return of({ logo: '', icon: '' });
      }),
      tap(patchState)
    );
  }
}
