import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService, ChargebeeState, detectAdBlock, MOBILE_ROUTES, UserState } from '@core/index';
import { BusinessState } from '@core/state/business.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Overlay } from 'apps/ayn-ui/src/public-api';
import { LayoutService } from '@pages/layout.service';

@Component({
  selector: 'aayn-mobile-topbar',
  templateUrl: './topbar.component.html'
})
export class MobileTopbarComponent implements OnInit {
  @Select(ChargebeeState.isAppsumoUser) isAppsumoUser$?: Observable<boolean>;

  @Select(ChargebeeState.isCancelledScheduled) isCancelledScheduled$?: Observable<boolean>;

  @Input() isMobileMenu: boolean = false;

  @ViewChild(Overlay) overlay!: Overlay;

  protected routes = MOBILE_ROUTES;

  @Input() hasAnyBusiness: boolean = false;

  @Input() isInviterUser: boolean = false;

  @Output() mobileMenuClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Select(BusinessState.HasAnyBusiness) hasAnyBusiness$!: Observable<boolean>;

  @Select(UserState.getCompanyLogo) logo$!: Observable<string>;

  defaultLogo: string = '/assets/images/svgs/logo.svg';

  detectedAdBlock = false;

  constructor(private authService: AuthService, public layoutService: LayoutService) {}

  ngOnInit() {
    detectAdBlock().then((o) => {
      this.detectedAdBlock = o;
    });
  }

  closeMenu(value: boolean) {
    this.isMobileMenu = value;
    this.mobileMenuClick.emit(value);
  }

  actionClick(actions: string) {
    switch (actions) {
      case 'logout':
        this.logout();
        break;
      case 'help-center':
        this.openHelpCenter();
        break;
    }
  }

  logout() {
    this.authService.logoutWithOperations();
  }

  openHelpCenter() {
    window.open('https://help-articles.adyouneed.com/', '_blank');
    this.overlay.hide();
  }
}
