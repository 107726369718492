import { HttpStatusCode } from '@angular/common/http';

export enum SystemTheme {
  DARK = 'dark',
  LIGHT = 'light'
}

export interface IResponseError {
  message: string;

  path?: string[];

  extensions: {
    code: string;
    response: {
      statusCode: HttpStatusCode;
      message: string;
    };
  };
}

export class BaseResponse<T = any> {
  errors?: IResponseError[];
  data?: T;
  __aayn_requestBody?: any;

  constructor(props: BaseResponse) {
    Object.assign(this, props);
  }
}

export interface IRestError {
  error: string;
  message: string[];
  statusCode: HttpStatusCode;
}

export interface IPaginatedResponse<T> {
  edges: { node: T }[];

  pageInfo: IPageInfo;
}

export interface IPageInfo {
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface OffsetPaginationArgs {
  skip?: number;
  take?: number;
}

export interface CursorPaginationArgs {
  first?: number;
  after?: string;
}

export interface KeyName<K = string, V = string> {
  key: K;
  name: V;
}

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type NotUndefined<T> = Exclude<T, undefined>;

export type ArrayElement<A extends readonly unknown[]> = A[number];

export type MergeArrayElement<A extends readonly unknown[], T> = Array<ArrayElement<A> & T>;
