import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { tapFst, tapSnd } from './derive';

function tapWhenFn<T>(predicate: (value: T) => boolean, func: (value: T) => void) {
  return function _tapWhenFn(value: T) {
    if (predicate(value)) {
      func(value);
    }
  };
}

/**
 * Tap into the stream when the predicate is true.
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 */
export function tapWhen<T, S extends T>(
  predicate: (value: T) => value is S,
  func: (value: S) => void
): MonoTypeOperatorFunction<T>;
/**
 * Tap into the stream when the predicate is true.
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 */
export function tapWhen<T>(predicate: (value: T) => boolean, func: (value: T) => void): MonoTypeOperatorFunction<T>;
/**
 * Tap into the stream when the predicate is true.
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 */
export function tapWhen<T>(predicate: (value: T) => boolean, func: (value: T) => void) {
  return function _tapWhen(source: Observable<T>) {
    return source.pipe(tap(tapWhenFn(predicate, func)));
  };
}

/**
 * Tap into the stream with source's first value when predicate is true.
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 */
export function tapWhenFst<First, Source extends First, S>(
  predicate: (first: First) => first is Source,
  func: (value: First) => void
): MonoTypeOperatorFunction<[First, S]>;

/**
 * Tap into the stream with source's first value when predicate is true.
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 */
export function tapWhenFst<First, S>(
  predicate: (first: First) => boolean,
  func: (value: First) => void
): MonoTypeOperatorFunction<[First, S]>;
/**
 * Tap into the stream with source's first value when predicate is true.
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 */
export function tapWhenFst<First, S>(predicate: (first: First) => boolean, func: (value: First) => void) {
  return function _tapWhenFst(source: Observable<[First, S]>) {
    return source.pipe(tapFst(tapWhenFn(predicate, func)));
  };
}

/**
 * Tap into the stream with source's second value when predicate is true
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 *
 */
export function tapWhenSnd<F, Second, Source extends Second>(
  predicate: (second: Second) => second is Source,
  func: (value: Source) => void
): MonoTypeOperatorFunction<[F, Second]>;
/**
 * Tap into the stream with source's second value when predicate is true
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 *
 */
export function tapWhenSnd<F, Second>(
  predicate: (second: Second) => boolean,
  func: (value: Second) => void
): MonoTypeOperatorFunction<[F, Second]>;
/**
 * Tap into the stream with source's second value when predicate is true
 * @param predicate The condition to check.
 * @param func The function to run when the condition is true.
 *
 */
export function tapWhenSnd<F, Second>(predicate: (second: Second) => boolean, func: (second: Second) => void) {
  return function _tapWhenSnd(source: Observable<[F, Second]>) {
    return source.pipe(tapSnd(tapWhenFn(predicate, func)));
  };
}
