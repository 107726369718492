import { pipe } from 'fp-ts/lib/function';
import { cloneDeep } from 'lodash';
import { modifyW, remove, upsert } from 'spectacles-ts';

import { DatepickerValue } from '@ayn-ui/lib/modules/base/datepicker';
import { FacebookCreateAd } from '@core/ad-platforms';
import { ICreateAdSelectItem } from '@pages/create-ad/models';

import { IPaginatedResponse } from '../base.model';
import { Base } from './base';

export module Facebook {
  /**
   * The ad account status.
   */
  export enum AdAccountStatus {
    Active = 1,
    Closed = 101,
    Disabled = 2,
    Unsettled = 3,
    AnyActive = 201,
    AnyClosed = 202,
    InGracePeriod = 9,
    PendingClosure = 100,
    PendingRiskReview = 7,
    PendingSettlement = 8
  }

  export interface IAdAccount {
    id: string;
    name: string;
    currency: string;
    accountStatus: AdAccountStatus;
    pageId?: string;
    instagramAccountId?: string;

    /**
     * For ui
     */

    selected?: boolean;
  }

  export interface IAdAccountPaging {
    hasNext: boolean;
    hasPrevious: boolean;
    next: string;
    previous: string;
  }

  export interface IAdPixel {
    id: string;
    name: string;
  }

  export interface IBusinessPageDetail {
    id: string;
    name: string;
    category: string;
    imageUrl: string;
  }

  export interface IBusinessAdAccountDetail {
    minimumBudget: number;
    currency: string;
  }

  export interface ICustomConversion {
    id: string;
    name: string;
    rule: string;
    customEventType: CustomEventType | string;
    eventSourceType: string;
    pixelId: string;
    isArchived: boolean;
    isUnavailable: boolean;
  }

  export interface IInstagramAccount {
    id: string;
    name: string;
    profilePictureUrl: string;
    followCount: number;
    followedByCount: number;
  }

  export interface IPagePost {
    id: string;
    message: string;
    imageUrl: string;
    shares: number;
    likes: number;
    comments: number;
    createdAt: Date;
  }

  export interface IInstagramMedia {
    id: string;
    imageUrl: string;
    likes: number;
    comments: number;
    caption: string;
    type: string;
    createdAt: Date;
  }

  export interface IPage {
    id: string;
    name: string;
    imageUrl: string;
    category: string;
  }

  export interface IConnectAdAccountRequest {
    contextId: string;
    adAccountId: string;
    pageId: string;
    instagramAccountId: string;
  }

  export interface IConnectAdAccountResponse {
    businessId: string;
    connectedAdAccountId: string;
  }

  export module Dashboard {
    export interface GetCampaignsRequest {
      dateRange?: {
        start: Date;
        end?: Date;
      };
      first?: number;
      after?: string;
      name?: string;
      last?: number;
      before?: string;
    }

    export type BreakdownType = keyof IAccountBreakdownResponse;

    export type IAccountBreakdownByBreakdownType<T extends BreakdownType> = IBaseBreakdown & {
      [K in T]: string;
    };

    export interface IAccountBreakdownResponse {
      age: Facebook.Dashboard.IAccountBreakdownByBreakdownType<'age'>[];
      gender: Facebook.Dashboard.IAccountBreakdownByBreakdownType<'gender'>[];
      country: Facebook.Dashboard.IAccountBreakdownByBreakdownType<'country'>[];
    }

    export interface IBaseBreakdown {
      costPerClick: string;
      clicks: string;
      averageCost1KImpressions: string;
      averageCost1KReach: string;
      impressions: string;
      ctr: string;
      reach: string;
    }

    export interface IInsightRequest extends Pick<Base.Dashboard.Global.IStartEndDateRequest, 'datePreset'> {
      dateRange?: Pick<Base.Dashboard.Global.IStartEndDateRequest, 'start' | 'end'>;
      timeIncrement?: number;
    }

    export interface IInsightResponse {
      insights: Facebook.Dashboard.IInsight[];
      summary: Facebook.Dashboard.IInsight;
    }

    export interface IAction {
      type: string;
      cost: number;
      value: string;
    }

    export interface ICampaign {
      id: string;
      status: string;
      name: string;
      startTime: Date;
      stopTime: Date;
      dailyBudget: string;
      lifeTimeBudget: string;
      insights: IInsight & { cpc: number };
    }

    export interface IUpdateCampaignRequest {
      campaignId: string;
      payload: IUpdateCampaignInput;
    }

    export interface IUpdateCampaignInput {
      name: string;
      lifeTimeBudget: string;
      dailyBudget: string;
    }

    export interface IGetAdsetsOrAdRequest extends Omit<Base.Dashboard.Global.IStartEndDateRequest, 'start' | 'end'> {
      name?: string;
      nodeId?: string;

      dateRange: Base.Dashboard.Global.IDateRangeInput;
    }

    export interface IGetAdsetsResponse {
      id: string;
      status: string;
      name: string;
      startTime: string | Date;
      endTime: string | Date;
      dailyBudget: string | number;
      lifeTimeBudget: string | number;
      campaignId: string;
      isDynamicCreative: boolean;
      billingEvent: BillingEvent;
      bidStrategy?: string;
      optimizationGoal: OptimizationGoal;
      promotedObject?: PromotedObject;
      insights: IInsight;
    }

    export interface IUpdateAdObjectStatusRequest {
      adObjectId: string;
      status: FacebookEntityStatus;
    }

    export interface IInsight {
      averageCost1KImpressions: number;
      averageCost1KReach: number;
      clicks: number;
      costPerClick: number;
      ctr: number;
      impressions: number;
      reach: number;
      spend: number;
      conversions: string;

      startDate: string;
      endDate: string;
      actions: IAction[];
    }
  }

  export enum CampaignObjective {
    OUTCOME_LEADS = 'OUTCOME_LEADS',
    OUTCOME_SALES = 'OUTCOME_SALES',
    OUTCOME_ENGAGEMENT = 'OUTCOME_ENGAGEMENT',
    OUTCOME_AWARENESS = 'OUTCOME_AWARENESS',
    OUTCOME_TRAFFIC = 'OUTCOME_TRAFFIC',
    OUTCOME_APP_PROMOTION = 'OUTCOME_APP_PROMOTION',
    // TYPE FOR COMMON CONVERSION
    AYN_CONVERSION = 'AYN_CONVERSION'
  }

  export enum FacebookEntityStatus {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted',
    Paused = 'Paused'
  }

  export enum OptimizationGoal {
    IMPRESSIONS = 'IMPRESSIONS',
    POST_ENGAGEMENT = 'POST_ENGAGEMENT',
    OFFSITE_CONVERSIONS = 'OFFSITE_CONVERSIONS',
    LINK_CLICKS = 'LINK_CLICKS',
    REACH = 'REACH',
    VALUE = 'VALUE',
    QUALITY_LEAD = 'QUALITY_LEAD',
    QUALITY_CALL = 'QUALITY_CALL',
    APP_INSTALLS = 'APP_INSTALLS',
    CONVERSATIONS = 'CONVERSATIONS',
    PAGE_LIKES = 'PAGE_LIKES',
    THRUPLAY = 'THRUPLAY',
    ENGAGED_USERS = 'ENGAGED_USERS',
    EVENT_RESPONSES = 'EVENT_RESPONSES',
    AD_RECALL_LIFT = 'AD_RECALL_LIFT',
    LANDING_PAGE_VIEWS = 'LANDING_PAGE_VIEWS',
    LEAD_GENERATION = 'LEAD_GENERATION',
    REPLIES = 'REPLIES',
    SOCIAL_IMPRESSIONS = 'SOCIAL_IMPRESSIONS',
    TWO_SECOND_CONTINUOUS_VIDEOS_VIEWS = 'TWO_SECOND_CONTINUOUS_VIDEOS_VIEWS'
  }

  export enum BillingEvent {
    IMPRESSIONS = 'IMPRESSIONS',
    LINK_CLICKS = 'LINK_CLICKS',
    POST_ENGAGEMENT = 'POST_ENGAGEMENT',
    TWO_SECOND_CONTINUOUS_VIDEO_VIEWS = 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
    VIDEO_VIEWS = 'VIDEO_VIEWS',
    PAGE_LIKES = 'PAGE_LIKES',
    OFFER_CLAIM = 'OFFER_CLAIM'
  }

  export enum BuyingType {
    Auction = 'AUCTION',
    Reserved = 'RESERVED',
    FixedCpm = 'FIXED_CPM'
  }

  export enum AdType {
    ImageAds,
    VideoAds,
    CarouselAds,
    InstantExperienceAds,
    AppAds,
    InstagramAds,
    SegmentAssetCustomizationAds,
    PlacementAssetCustomizationAds,
    MultiLanguageAds,
    DynamicAds,
    DynamicCreative,
    CollectionAds,
    AdsThatClickToMessenger,
    EventAndLocalAds,
    OfferAds,
    SponsoredMessengerMessage,
    CollaborativeAds,
    InstagramExperienceAds,
    LeadAds
  }

  export const AD_TYPES: Partial<Record<AdType, string>> = {
    [AdType.DynamicCreative]: 'Dynamic Creative Ads',
    [AdType.CarouselAds]: 'Carousel Ads',
    [AdType.InstantExperienceAds]: 'Facebook Post',
    [AdType.InstagramExperienceAds]: 'Instagram Post'
  };

  export enum EffectiveStatus {
    Active = 'ACTIVE',
    Paused = 'PAUSED',
    Deleted = 'DELETED',
    Archived = 'ARCHIVED',
    InProcess = 'IN_PROCESS',
    WithIssues = 'WITH_ISSUES',
    Disapproved = 'DISAPPROVED',
    PreApproved = 'PREAPPROVED',
    AdsetPaused = 'ADSET_PAUSED',
    PendingReview = 'PENDING_REVIEW',
    CampaignPaused = 'CAMPAIGN_PAUSED',
    PendingBillingInfo = 'PENDING_BILLING_INFO'
  }

  export enum LocationType {
    /**
     * People living in this location.
     */
    Home = 'home',

    /**
     * People recently in this location.
     */
    Recent = 'recent',

    /**
     * People travelling in this location.
     */
    TravelIn = 'travel_in'
  }

  export enum BidStrategy {
    COST_CAP = 'COST_CAP',
    LOWEST_COST_WITH_BID_CAP = 'LOWEST_COST_WITH_BID_CAP',
    LOWEST_COST_WITH_MIN_ROAS = 'LOWEST_COST_WITH_MIN_ROAS',
    LOWEST_COST_WITHOUT_CAP = 'LOWEST_COST_WITHOUT_CAP'
  }

  export type IdModel = {
    id: string;
  };

  export type IdNameModel = {
    name: string;
  } & IdModel;

  export type KeyModel = {
    key: string;
  };

  export type KeyNameModel = {
    name: string;
  } & KeyModel;

  export module Targeting {
    export module TargetingModel {
      export type Read = {
        ageMin: number;
        ageMax: number;
        locales?: number[];
        genders?: Gender[];
        geoLocations: Geographic.GeoLocation.Read;
        excludedGeoLocations?: Geographic.GeoLocation.Read;
        exclusions?: DetailedTargetingModel.Read;
        flexibleSpec?: DetailedTargetingModel.Read[];
        customAudiences?: IdNameModel[];
        excludedCustomAudiences?: IdNameModel[];
      };

      export type Write = {
        ageMin: number;
        ageMax: number;
        locales?: number[];
        genders?: Gender[];
        geoLocations: Geographic.GeoLocation.Write;
        excludedGeoLocations?: Geographic.GeoLocation.Write;
        exclusions?: DetailedTargetingModel.Write;
        flexibleSpec?: DetailedTargetingModel.Write[];
        customAudiences?: IdModel[];
        excludedCustomAudiences?: IdModel[];
      };
    }

    export module DetailedTargetingModel {
      export type Read = {
        educationStatuses?: number[];
        relationshipStatuses?: number[];
        income?: number[];
        interests?: number[];
        behaviors?: number[];
        industries?: number[];
        lifeEvents?: number[];
        familyStatuses?: number[];
        educationSchools?: number[];
        workEmployers?: number[];
        workPositions?: number[];
      };

      export type Write = {
        educationStatuses?: number[];
        relationshipStatuses?: number[];
        income?: number[];
        interests?: number[];
        behaviors?: number[];
        industries?: number[];
        lifeEvents?: number[];
        familyStatuses?: number[];
        educationSchools?: number[];
        workEmployers?: number[];
        workPositions?: number[];
      };

      function clearEmptyArrayFieldsInObject<T extends Object>(obj: T) {
        Object.keys(obj).forEach((key) => {
          const value = obj[key];

          if (!value.length) delete obj[key];
        });

        return obj;
      }

      export function composeDetailedTargetings(detailedTargetings: DetailedTargetingItem[]) {
        const mappedDetailedTargetings = {
          educationStatuses: detailedTargetings.filter((o) => o.type === 'educationStatuses').map((o) => +o.id),
          relationshipStatuses: detailedTargetings.filter((o) => o.type === 'relationshipStatuses').map((o) => +o.id),
          income: detailedTargetings.filter((o) => o.type === 'income').map((o) => +o.id),
          behaviors: detailedTargetings.filter((o) => o.type === 'behaviors').map((o) => +o.id),
          educationSchools: detailedTargetings.filter((o) => o.type === 'educationSchools').map((o) => +o.id),
          familyStatuses: detailedTargetings.filter((o) => o.type === 'familyStatuses').map((o) => +o.id),
          industries: detailedTargetings.filter((o) => o.type === 'industries').map((o) => +o.id),
          interests: detailedTargetings.filter((o) => o.type === 'interests').map((o) => +o.id),
          lifeEvents: detailedTargetings.filter((o) => o.type === 'lifeEvents').map((o) => +o.id),
          workEmployers: detailedTargetings.filter((o) => o.type === 'workEmployers').map((o) => +o.id),
          workPositions: detailedTargetings.filter((o) => o.type === 'workPositions').map((o) => +o.id)
        } as Write;

        return clearEmptyArrayFieldsInObject(mappedDetailedTargetings);
      }

      export interface DetailedTargetingItem {
        id: string;
        name: string;
        type: keyof Write;
        path: string[];
        audienceSizeLowerBound: number;
        audienceSizeUpperBound: number;
      }
    }

    export module Geographic {
      export module GeoLocation {
        export type BaseGeoLocationModel = {
          key: string;
          name: string;
          country?: string;
        };

        export type Read = {
          countries?: string[];
          countryGroups?: string[];
          cities?: BaseGeoLocationModel[];
          regions?: BaseGeoLocationModel[];
          geoMarkets?: BaseGeoLocationModel[];
          customLocations?: BaseGeoLocationModel[];
          zips?: BaseGeoLocationModel[];
          locationTypes?: LocationType[];
          largeGeoAreas?: BaseGeoLocationModel[];
          mediumGeoAreas?: BaseGeoLocationModel[];
          smallGeoAreas?: BaseGeoLocationModel[];
          subCities?: BaseGeoLocationModel[];
          neighborHoods?: BaseGeoLocationModel[];
        };

        export type Write = {
          countries?: string[];
          cities?: string[];
          countryGroups?: string[];
          regions?: string[];
          geoMarkets?: string[];
          customLocations?: string[];
          zips?: string[];
          largeGeoAreas?: string[];
          mediumGeoAreas?: string[];
          smallGeoAreas?: string[];
          subCities?: string[];
          neighborHoods?: string[];
          locationTypes?: LocationType[];
        };
      }
    }

    export enum Gender {
      Men = 'Men',
      Women = 'Women'
    }
  }

  export enum CustomEventType {
    Rate = 'Rate',
    TutorialCompletion = 'TutorialCompletion',
    Contact = 'Contact',
    CustomizeProduct = 'CustomizeProduct',
    Donate = 'Donate',
    FindLocation = 'FindLocation',
    Schedule = 'Schedule',
    StartTrial = 'StartTrial',
    SubmitApplication = 'SubmitApplication',
    Subscribe = 'Subscribe',
    AddToCart = 'AddToCart',
    AddToWishList = 'AddToWishList',
    InitiatedCheckout = 'InitiatedCheckout',
    AddPaymentInfo = 'AddPaymentInfo',
    Purchase = 'Purchase',
    Lead = 'Lead',
    CompleteRegistration = 'CompleteRegistration',
    ContentView = 'ContentView',
    Search = 'Search',
    ServiceBookingRequest = 'ServiceBookingRequest',
    MessagingConversationStarted7D = 'MessagingConversationStarted7D',
    LevelAchieved = 'LevelAchieved',
    AchievementUnlocked = 'AchievementUnlocked',
    SpentCredits = 'SpentCredits',
    ListingInteraction = 'ListingInteraction',
    D2Retention = 'D2Retention',
    D7Retention = 'D7Retention',
    Other = 'Other'
  }

  export enum CallToActionType {
    Buy = 'Buy',
    Call = 'Call',
    Share = 'Share',
    UseApp = 'UseApp',
    CallMe = 'CallMe',
    SignUp = 'SignUp',
    BuyNow = 'BuyNow',
    Donate = 'Donate',
    ShopNow = 'ShopNow',
    SellNow = 'SellNow',
    Contact = 'Contact',
    CallNow = 'CallNow',
    Moments = 'Moments',
    SeeMore = 'SeeMore',
    GetOffer = 'GetOffer',
    GetQuote = 'GetQuote',
    OrderNow = 'OrderNow',
    AddToCart = 'AddToCart',
    ContactUs = 'ContactUs',
    RecordNow = 'RecordNow',
    ListenNow = 'ListenNow',
    EventRsvp = 'EventRsvp',
    SendAGift = 'SendAGift',
    SeeMenu = 'SeeMenu',
    PlayGame = 'PlayGame',
    SendMessage = 'SendMessage',
    LikePage = 'LikePage',
    OpenLink = 'OpenLink',
    Download = 'Download',
    Save = 'Save',
    SendWhatsAppMessage = 'SendWhatsAppMessage',
    NoButton = 'NoButton',
    ApplyNow = 'ApplyNow',
    Subscribe = 'Subscribe',
    GetAccess = 'GetAccess',
    DonateNow = 'DonateNow',
    SayThanks = 'SayThanks',
    UpdateApp = 'UpdateApp',
    WatchMore = 'WatchMore',
    VideoCall = 'VideoCall',
    LearnMore = 'LearnMore',
    InstallApp = 'InstallApp',
    BuyTickets = 'BuyTickets',
    WatchVideo = 'WatchVideo',
    BookTravel = 'BookTravel',
    BookNow = 'BookNow',
    FollowPage = 'FollowPage',
    FollowUser = 'FollowUser',
    StartOrder = 'StartOrder',
    FindAGroup = 'FindAGroup',
    SwipeUpShop = 'SwipeUpShop',
    PayToAccess = 'PayToAccess',
    RequestTime = 'RequestTime',
    MessagePage = 'MessagePage',
    ListenMusic = 'ListenMusic',
    ReferFriends = 'ReferFriends',
    GetShowTimes = 'GetShowTimes',
    UseMobileApp = 'UseMobileApp',
    GetOfferView = 'GetOfferView',
    GetPromotions = 'GetPromotions',
    GetDirections = 'GetDirections',
    SendGiftMoney = 'SendGiftMoney',
    VisitPagesFeed = 'VisitPagesFeed',
    MobileDownload = 'MobileDownload',
    SottoSubscribe = 'SottoSubscribe',
    SwipeUpProduct = 'SwipeUpProduct',
    WhatsappMessage = 'WhatsappMessage',
    VideoAnnotation = 'VideoAnnotation',
    WoodhengeSupport = 'WoodhengeSupport',
    InstallMobileApp = 'InstallMobileApp',
    PurchaseGiftCards = 'PurchaseGiftCards',
    PlayGameOnFacebook = 'PlayGameOnFacebook',
    FollowNewsStoryLine = 'FollowNewsStoryLine'
  }

  export const CallToActionTypes = Object.values(CallToActionType);

  export type SupportedCampaignObjective =
    | CampaignObjective.OUTCOME_TRAFFIC
    | CampaignObjective.OUTCOME_LEADS
    | CampaignObjective.OUTCOME_SALES
    | CampaignObjective.OUTCOME_ENGAGEMENT;

  export type SupportedAdType =
    | AdType.DynamicCreative
    | AdType.CarouselAds
    | AdType.InstantExperienceAds
    | AdType.InstagramExperienceAds;

  const commonEventTypes = [
    CustomEventType.Search,
    CustomEventType.StartTrial,
    CustomEventType.Subscribe,
    CustomEventType.ContentView,
    CustomEventType.Other
  ];

  export const conversionEventsByObjective: {
    [key in Exclude<SupportedCampaignObjective, CampaignObjective.OUTCOME_TRAFFIC>]: CustomEventType[];
  } = {
    [CampaignObjective.OUTCOME_LEADS]: [
      ...commonEventTypes,
      CustomEventType.CompleteRegistration,
      CustomEventType.Contact,
      CustomEventType.FindLocation,
      CustomEventType.Lead,
      CustomEventType.Schedule,
      CustomEventType.SubmitApplication
    ],
    [CampaignObjective.OUTCOME_SALES]: [
      ...commonEventTypes,
      CustomEventType.AddPaymentInfo,
      CustomEventType.AddToCart,
      CustomEventType.AddToWishList,
      CustomEventType.CompleteRegistration,
      CustomEventType.Donate,
      CustomEventType.InitiatedCheckout,
      CustomEventType.Purchase
    ],
    [CampaignObjective.OUTCOME_ENGAGEMENT]: [
      ...commonEventTypes,
      CustomEventType.AddToWishList,
      CustomEventType.Contact,
      CustomEventType.CustomizeProduct,
      CustomEventType.Donate,
      CustomEventType.FindLocation,
      CustomEventType.Schedule,
      CustomEventType.SubmitApplication
    ]
  };

  const commonCtas: CallToActionType[] = [
    CallToActionType.ApplyNow,
    CallToActionType.BookNow,
    CallToActionType.ContactUs,
    CallToActionType.Download,
    CallToActionType.GetShowTimes,
    CallToActionType.LearnMore,
    CallToActionType.ListenNow,
    CallToActionType.OrderNow,
    CallToActionType.RequestTime,
    CallToActionType.ShopNow,
    CallToActionType.SignUp,
    CallToActionType.Subscribe,
    CallToActionType.WatchMore
  ];

  export const callToActionsByObjectiveAndAdType: {
    [key in SupportedCampaignObjective]: { [key in SupportedAdType]: CallToActionType[] };
  } = {
    [CampaignObjective.OUTCOME_TRAFFIC]: {
      [AdType.DynamicCreative]: [
        ...commonCtas,
        CallToActionType.GetAccess,
        CallToActionType.GetPromotions,
        CallToActionType.GetQuote,
        CallToActionType.SeeMenu
        //TODO: Add cta below when cta added to facebook cta list. Currently this cta can be selectable in facebook ads manager
        // CallToActionType.GetUpdates,
      ],
      [AdType.CarouselAds]: [
        ...commonCtas,
        CallToActionType.GetAccess,
        CallToActionType.GetOffer,
        CallToActionType.GetPromotions,
        CallToActionType.GetQuote,
        CallToActionType.SeeMenu
        //TODO: Add cta below when cta added to facebook cta list. Currently this cta can be selectable in facebook ads manager
        // CallToActionType.GetUpdates,
      ],
      [AdType.InstagramExperienceAds]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetAccess,
        CallToActionType.GetOffer,
        CallToActionType.GetPromotions,
        CallToActionType.GetQuote,
        CallToActionType.SeeMenu,
        CallToActionType.SendMessage
        //TODO: Add ctas below when cta added to facebook cta list. Currently this cta can be selectable in facebook ads manager
        // CallToActionType.GetUpdates,
        // CallToActionType.SendInstagramMessage,

        //TODO: Open when control ad account has whatsapp business account
        // CallToActionType.SendWhatsAppMessage,
      ],
      [AdType.InstantExperienceAds]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetAccess,
        CallToActionType.GetOffer,
        CallToActionType.GetPromotions,
        CallToActionType.SendMessage
        //TODO: Add cta below when cta added to facebook cta list. Currently this cta can be selectable in facebook ads manager
        // CallToActionType.SendInstagramMessage,
        //TODO: Open when control ad account has whatsapp business account
        // CallToActionType.SendWhatsAppMessage,
      ]
    },
    [CampaignObjective.OUTCOME_LEADS]: {
      [AdType.DynamicCreative]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
      ],
      [AdType.CarouselAds]: [
        ...commonCtas,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
      ],
      [AdType.InstagramExperienceAds]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
      ],
      [AdType.InstantExperienceAds]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
      ]
    },
    [CampaignObjective.OUTCOME_SALES]: {
      [AdType.DynamicCreative]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
      ],
      [AdType.CarouselAds]: [
        ...commonCtas,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
      ],
      [AdType.InstagramExperienceAds]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu,
        CallToActionType.SendMessage
        //TODO: Add cta below when cta added to facebook cta list. Currently this cta can be selectable in facebook ads manager
        // CallToActionType.SendInstagramMessage
        //TODO: Open when control ad account has whatsapp business account
        // CallToActionType.SendWhatsAppMessage,
      ],
      [AdType.InstantExperienceAds]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu,
        CallToActionType.SendMessage
        //TODO: Add cta below when cta added to facebook cta list. Currently this cta can be selectable in facebook ads manager
        // CallToActionType.SendInstagramMessage
        //TODO: Open when control ad account has whatsapp business account
        // CallToActionType.SendWhatsAppMessage,
      ]
    },
    [CampaignObjective.OUTCOME_ENGAGEMENT]: {
      [AdType.DynamicCreative]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
      ],
      [AdType.CarouselAds]: [
        ...commonCtas,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
      ],
      [AdType.InstagramExperienceAds]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
        //TODO: Add cta below when cta added to facebook cta list. Currently this cta can be selectable in facebook ads manager
        // CallToActionType.SendInstagramMessage,
      ],
      [AdType.InstantExperienceAds]: [
        ...commonCtas,
        CallToActionType.BuyTickets,
        CallToActionType.GetOffer,
        CallToActionType.GetQuote,
        CallToActionType.PlayGame,
        CallToActionType.SeeMenu
        //TODO: Add cta below when cta added to facebook cta list. Currently this cta can be selectable in facebook ads manager
        // CallToActionType.SendInstagramMessage,
      ]
    }
  };

  export const CUSTOM_EVENT_TYPES: Array<CustomEventType> = Array.from(
    Object.values(conversionEventsByObjective).reduce((acc, eventTypes) => {
      eventTypes.forEach((eventType) => acc.add(eventType));
      return acc;
    }, new Set<CustomEventType>())
  );

  export function decideObjectiveTypesByEvent(event: CustomEventType): Array<SupportedCampaignObjective> {
    return Object.entries(conversionEventsByObjective).reduce((acc, [objective, events]) => {
      if (events.includes(event)) {
        acc.push(objective as SupportedCampaignObjective);
      }
      return acc;
    }, [] as SupportedCampaignObjective[]);
  }

  export function getCallToActionsByObjectiveAndAdType(
    objective: SupportedCampaignObjective,
    adType: SupportedAdType
  ): CallToActionType[] {
    return callToActionsByObjectiveAndAdType[objective][adType];
  }

  export function decideCallToActionsByEvent(event: CustomEventType, adType: SupportedAdType): CallToActionType[] {
    const objectives = decideObjectiveTypesByEvent(event);
    return Array.from(
      new Set(objectives.flatMap((objective) => getCallToActionsByObjectiveAndAdType(objective, adType)))
    );
  }

  export function getObjectiveTypeByEventAndCta(
    event: CustomEventType,
    adType: SupportedAdType,
    cta: CallToActionType
  ): SupportedCampaignObjective {
    const objectives = decideObjectiveTypesByEvent(event);
    const objective = objectives.find((objective) =>
      callToActionsByObjectiveAndAdType[objective][adType].includes(cta)
    );
    if (!objective) {
      throw new Error(`Objective not found for event ${event} and cta ${cta}`);
    }
    return objective;
  }
  export interface PromotedObject {
    pixelId?: string;
    customEventType?: CustomEventType | string;
    customConversionId?: string;
    pixelRule?: string;
  }

  export enum AdFormat {
    Post = 'Post',
    Carousel = 'Carousel',
    Automatic = 'Automatic',
    Collection = 'Collection',
    SingleVideo = 'SingleVideo',
    SingleImage = 'SingleImage',
    CarouselImage = 'CarouselImage',
    CarouselVideo = 'CarouselVideo'
  }

  export interface LinkUrlInput {
    websiteUrl: string;
    displayUrl?: string;
  }

  export interface DynamicAssetsInput {
    formats: AdFormat[];
    bodies: string[];
    titles: string[];
    descriptions: string[];
    linkUrls: LinkUrlInput[];
    callToActionTypes: CallToActionType[];

    images: IdWithUrl[];
    videos: IdWithUrl[];
  }

  export interface AdIdentityInput {
    pageId: string;
    instagramAccountId: string;
  }

  export type AdCreationCreativeInput =
    | AdDynamicCreativeInput
    | AdCarouselCreativeInput
    | AdExistingPostPage
    | AdExistingPostInstagram;

  export interface AdDynamicCreativeInput {
    name: string;
    optimizeCreativeForEachPerson: boolean;
    dynamicAssets: DynamicAssetsInput;
    identity?: AdIdentityInput;
  }

  export interface ICarouselCard {
    description: string;
    headline: string;
    imageHash?: string;

    videoId?: string;
    videoImage?: string;

    /**
     * For Draft
     */

    imageUrl?: string;
  }

  export interface IDestination {
    displayUrl: string;
    url: string;
  }

  export interface AdCarouselCreativeInput {
    name: string;
    primaryText: string;
    callToAction: CallToActionType;
    identity: AdIdentityInput;
    carouselCards: ICarouselCard[];
    optimizeCreativeForEachPerson: boolean;
    destination: IDestination;
  }

  export interface AdExistingPost {
    name: string;
    callToAction: CallToActionType;
    destination: IDestination;
  }

  export interface AdExistingPostInstagram extends AdExistingPost {
    instagramMediaId: string;
  }

  export interface AdExistingPostPage extends AdExistingPost {
    pagePostId: string;
  }

  export interface AdImageModel {
    id: string;
    name?: string;
    url: string;
    url_128: string;
    hash: string;

    // For UI
    selected?: boolean;
  }

  export type IGetAdImagesResponse = IPaginatedResponse<Facebook.AdImageModel>;

  export type IGetAdVideosResponse = IPaginatedResponse<Facebook.AdVideoModel>;

  export interface AdVideoModel {
    id: string;
    source: string;
    title?: string;
    embed_html: string;
    embeddable: boolean;
    status?: {
      video_status: string;
    };
    thumbnails?: ThumbnailModel[];

    // For UI
    selected?: boolean;
  }

  export interface ThumbnailModel {
    id: string;
    url: string;
    isPreferred: boolean;
    width: number;
    height: number;
  }

  export interface SavedAudienceItem {
    id: string;
    name: string;
    targeting: Targeting.TargetingModel.Read;
    audienceSizeLowerBound: number;
    audienceSizeUpperBound: number;
  }

  export type CustomAudienceItem = Omit<SavedAudienceItem, 'targeting'>;

  export enum GeoLocationType {
    Zip = 'Zip',
    City = 'City',
    Region = 'Region',
    Country = 'Country',
    SubCity = 'SubCity',
    GeoMarket = 'GeoMarket',
    LargeGeoArea = 'LargeGeoArea',
    CountryGroup = 'CountryGroup',
    SmallGeoArea = 'SmallGeoArea',
    Neighborhood = 'Neighborhood',
    MediumGeoArea = 'MediumGeoArea',
    MetroArea = 'MetroArea'
  }

  export function composeLocationTypes(geoLocations: GeoLocationItem[]) {
    return {
      countries: geoLocations.filter((o) => o.type === GeoLocationType.Country).map((o) => o.key),
      countryGroups: geoLocations.filter((o) => o.type === GeoLocationType.CountryGroup).map((o) => o.key),
      cities: geoLocations.filter((o) => o.type === GeoLocationType.City).map((o) => o.key),
      geoMarkets: geoLocations.filter((o) => o.type === GeoLocationType.GeoMarket).map((o) => o.key),
      largeGeoAreas: geoLocations.filter((o) => o.type === GeoLocationType.LargeGeoArea).map((o) => o.key),
      mediumGeoAreas: geoLocations.filter((o) => o.type === GeoLocationType.MediumGeoArea).map((o) => o.key),
      smallGeoAreas: geoLocations.filter((o) => o.type === GeoLocationType.SmallGeoArea).map((o) => o.key),
      neighborHoods: geoLocations.filter((o) => o.type === GeoLocationType.Neighborhood).map((o) => o.key),
      regions: geoLocations.filter((o) => o.type === GeoLocationType.Region).map((o) => o.key),
      subCities: geoLocations.filter((o) => o.type === GeoLocationType.SubCity).map((o) => o.key),
      zips: geoLocations.filter((o) => o.type === GeoLocationType.Zip).map((o) => o.key),
      metroAreas: geoLocations.filter((o) => o.type === GeoLocationType.MetroArea).map((o) => o.key)
    } as Targeting.Geographic.GeoLocation.Write;
  }

  export interface GeoLocationItem {
    key: string;
    name: string;
    type: GeoLocationType;
    countryCode?: string;
    countryCodes?: string[];
    countryName?: string;
    region?: string;
    regionId?: string;
    primaryCity?: string;
    primaryCityId?: string;
  }

  export interface IdTypeModel {
    id: string;
    type: string;
  }

  export enum AudienceType {
    Manual = 'MANUAL',
    AiAudienceFinder = 'AI_AUDIENCE_FINDER',
    Retargeting = 'RETARGETING'
  }

  export module UI {
    export type SelectAudienceItem = ICreateAdSelectItem<Facebook.AudienceType>;
  }

  export type AssetType = 'image' | 'video';

  export interface IdWithUrl {
    id: string;
    url: string;

    type?: AssetType;
    progress?: number;
  }

  export interface TargetingModel {
    minAge: number;
    maxAge: number;
    genders: Targeting.Gender[];
    geoLocations: Targeting.Geographic.GeoLocation.Write;
    excludedGeoLocations: Targeting.Geographic.GeoLocation.Write;
    flexibleDetailedTargetings: Targeting.DetailedTargetingModel.Write;
    excludedDetailedTargetings: Targeting.DetailedTargetingModel.Write;
    locales: number[];
  }

  export interface StartEndDate {
    start: string | DatepickerValue;
    end?: string | DatepickerValue;
  }

  export module CreateAd {
    export module Draft {
      export module AdSet {
        export function MapAdSetDraftToOriginalModel(adSetModel: FacebookCreateAd.AdSetModel) {
          return pipe(
            adSetModel,
            cloneDeep,
            modifyW('targeting.locales', (o) => o.map((l) => +l.key)),
            upsert(
              'targeting',
              'geoLocations',
              Facebook.composeLocationTypes(adSetModel.targeting.geoLocations.inclusions)
            ),
            upsert(
              'targeting',
              'excludedGeoLocations',
              Facebook.composeLocationTypes(adSetModel.targeting.geoLocations.exclusions)
            ),
            upsert(
              'targeting',
              'flexibleDetailedTargetings',
              Facebook.Targeting.DetailedTargetingModel.composeDetailedTargetings(
                adSetModel.targeting.detailedTargetings.inclusions
              )
            ),
            upsert(
              'targeting',
              'excludedDetailedTargetings',
              Facebook.Targeting.DetailedTargetingModel.composeDetailedTargetings(
                adSetModel.targeting.detailedTargetings.exclusions
              )
            ),
            remove('targeting.detailedTargetings')
          );
        }

        export module Targeting {
          export interface Location {
            inclusions: Facebook.GeoLocationItem[];
            exclusions: Facebook.GeoLocationItem[];
          }

          export interface DetailedTargeting {
            inclusions: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem[];
            exclusions: Facebook.Targeting.DetailedTargetingModel.DetailedTargetingItem[];
          }
        }
      }
    }

    export interface ICreateCampaignRequest {
      name: string;
      objective: CampaignObjective;
      status: FacebookEntityStatus;
      dailyBudget?: number | string;
      lifeTimeBudget?: number | string;
      bidStrategy: BidStrategy;
    }

    export interface ICreateAdSetRequest {
      name: string;
      status: FacebookEntityStatus;
      campaignId: string;
      isDynamicCreative: boolean;
      billingEvent: BillingEvent;
      optimizationGoal: OptimizationGoal;
      dateRange: StartEndDate;
      targeting: TargetingModel;
      promotedObject: PromotedObject;
    }

    export type AdType =
      | AdDynamicCreativeInput
      | AdCarouselCreativeInput
      | AdExistingPostInstagram
      | AdExistingPostPage;

    export interface ICreateAdRequest<AT = AdDynamicCreativeInput> {
      name: string;
      adsetId?: string;
      creative: AT;
      status: FacebookEntityStatus;

      /**
       * FIXME: Şu an backend için böyle yapıldı, ileride backend düzeldiğinde kalkacak!
       */
      isDynamicCreative?: boolean;
    }

    export type IEstimateReachRequest = TargetingModel;

    export interface IEstimateReachResponse {
      audienceSizeLowerBound: number;
      audienceSizeUpperBound: number;
      isValid: boolean;
    }

    export interface IUploadImageResponse {
      hash: string;
      url: string;
      name: string;
      url128: string;
      url256: string;
    }

    export interface IVideoThumbnail {
      id: string;
      url: string;
      isPreferred: boolean;
      width: number;
      height: number;
    }

    export interface IUploadVideoResponse {
      id: string;
      source: string;
      title: string;
      embedHtml: string;
      embeddable: boolean;
      status: string;
      thumbnails: IVideoThumbnail[];
    }
  }
}
