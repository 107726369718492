import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Google } from '@core/models';
import { GoogleAdPreviewService } from '@pages/create-ad/components/select-adtype/platforms/google/components/ad-preview/ad-preview.service';
import { mapAdTypes, selectedItem } from '@pages/create-ad/components/select-objective/utils';
import { ICreateAdSelectItem } from '@pages/create-ad/models';
import { GoogleStateService } from '@pages/create-ad/state';
import { provideLengthCalculator, TextInputLengthCalculator } from '@ayn-ui/lib/modules';
import { countWithCJK } from '@core/utils';

const AD_TYPES__GOOGLE: ICreateAdSelectItem<Google.AdType>[] = [
  {
    id: 1,
    title: 'Performance Max Ads',
    description: 'The newest and the best Google Ads format that includes Search/YouTube/Display Ads.',
    icon: 'dynamic',
    empty: false,
    active: false,
    type: Google.AdType.PerformanceMax
  },
  {
    id: 2,
    title: 'Display Ads',
    description: 'The perfect ad type is designed to display your ads across millions of websites.',
    icon: 'display',
    empty: false,
    active: false,
    type: Google.AdType.DisplayAds
  },
  {
    id: 3,
    title: 'Search Ads',
    description: 'The perfect ad type is designed to display your ads across millions of searchs.',
    icon: 'existing-post',
    empty: false,
    active: false,
    type: Google.AdType.SearchAds
  }
];

export const GOOGLE_INPUT_LENGTH_CALCULATOR: TextInputLengthCalculator = (val: string) => {
  if (!val.length) {
    return 0;
  }
  return countWithCJK(val, 2);
};

@Component({
  selector: 'aayn-select-ad-type--google',
  templateUrl: 'select-ad-type-content.component.html',
  providers: [GoogleAdPreviewService, provideLengthCalculator(GOOGLE_INPUT_LENGTH_CALCULATOR)]
})
export class SelectAdTypeContentGoogleComponent implements OnInit {
  selectedAdType!: Google.AdType;

  adTypes$ = this.googleStateService.adCreationModel$.pipe(
    mapAdTypes(AD_TYPES__GOOGLE, 'ad'),
    selectedItem((ad) => {
      if (ad.type !== undefined) {
        this.selectedAdType = ad.type;
      }
    })
  );

  protected AdTypes = Google.AdType;

  constructor(private googleStateService: GoogleStateService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  adTypeChangeStatus(item: ICreateAdSelectItem<Google.AdType>) {
    if (item.disabled) return;

    if (this.googleStateService.adCreationModel.selectedTypes.ad !== item.type) {
      this.googleStateService.changeAdType(item.type!);
    }
  }
}
