<aside class="ayn-layout--aside" [ngClass]="{ 'ayn-layout--aside__expanded': isMenuExpanded }">
  <a routerLink="/main/dashboard" class="ayn-layout--aside-logo">
    <ayn-icon
      *ngIf="!(logo$ | async)"
      name="logo"
      class="ayn-layout--aside-logo-large"
      srcPrefix="/assets/images/svgs/"
    ></ayn-icon>
    <ayn-icon
      *ngIf="!(logo$ | async)"
      name="logo-mini"
      class="ayn-layout--aside__logo-mini"
      srcPrefix="/assets/images/svgs/"
    ></ayn-icon>

    <img
      *ngIf="logo$ | async"
      [src]="(logo$ | async) || defaultLogo"
      #logoRef
      (error)="logoRef.src = defaultLogo"
      [alt]="'Logo' | translate"
      class="ayn-layout--aside-logo-large"
    />
    <img
      *ngIf="logo$ | async"
      [src]="(logo$ | async) || miniLogo"
      #miniRef
      (error)="miniRef.src = miniLogo"
      [alt]="'Logo' | translate"
      class="ayn-layout--aside__logo-mini"
    />
  </a>

  <button
    ayn-button="primary-sexy"
    ayn-button-block
    class="ayn-layout--aside__create-ad"
    [disabled]="!hasAnyBusiness"
    [routerLink]="['/main/create-ad']"
    [queryParams]="{ step: 1 }"
    (click)="resetCreateAdStore()"
  >
    {{ 'Create an ad' | translate }}
  </button>

  <ul class="ayn-layout--aside__menu">
    <li
      *ngFor="let route of routes"
      routerLinkActive="active"
      [ngClass]="{
        'hover:disabled disabled':
          route.disabled ||
          !hasAnyBusiness ||
          (route.needAdAccount && !hasAnyAdAccount) ||
          (route.needAdminAccount && isInviterUser)
      }"
    >
      <a
        [routerLink]="
          route.disabled ||
          !hasAnyBusiness ||
          (route.needAdAccount && !hasAnyAdAccount) ||
          (route.needAdminAccount && isInviterUser)
            ? null
            : route.url
        "
      >
        <ayn-icon *ngIf="route.badgeIcon" [name]="route.badgeIcon" class="aside__menu_icon-beta"></ayn-icon>

        <ayn-icon [name]="route.icon" className="aside__menu_icon"></ayn-icon>
        <span class="aside__menu-text">{{ route.name | translate }}</span>

        <ng-container *ngIf="route.url === '/main/university'">
          <img src="/assets/images/svgs/medal-{{ true ? 'active' : 'passive' }}.svg" alt="University" class="" />
        </ng-container>
      </a>
    </li>
  </ul>

  <aayn-package></aayn-package>

  <button
    ayn-button="white-shadow"
    [icon]="!isMenuExpanded ? 'menu' : 'cross-mini'"
    class="ayn-layout--aside-collapse"
    (click)="toggleExpand()"
  ></button>
</aside>
