import { makeMutation } from '../types';

type Input = {
  theme: string;
};

type Output = string;

export const setTheme = makeMutation<Output, Input>(`
mutation SetTheme($theme: String!) {
  setTheme(theme: $theme)
}
`);
