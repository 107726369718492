<form
  [formGroup]="form"
  autocomplete="off"
  #_form="ngForm"
  aaynTextAiContainer
  [adPreviewContainer]="adPreviewSmartCampaignService.dynamicCreative"
>
  <ayn-file-upload-area
    (fileUploadClick)="openFileModal()"
    (fileRemove)="removeFile($event)"
    [description]="
      'Based on your platforms, we may request creatives of different sizes for the best performance.' | translate
    "
    [imageGroups]="uploadedImageUrls"
    [showRequiredAlert]="_form.submitted && !isImagesValid"
    (imageError)="getImages($event.index!)"
    adPreview="images"
    [standAlone]="true"
    [imageCount]="5"
  ></ayn-file-upload-area>

  <aayn-text-generator-ai style="margin-top: 30px; display: block"></aayn-text-generator-ai>

  <aayn-ad-type-sections *aynSmartHasPlatform="[Platforms.TikTok]">
    <aayn-ad-type-section
      icon="tiktok"
      iconDirection="right"
      containerClass="aayn-smart-campaign__tiktok-identity"
      header="Business Logo & Name"
      description="Upload your logo and write the business name that will be used in ads."
    >
      <aayn-identity-select
        formControlName="identity"
        adPreview="tiktokBusiness"
        [standAlone]="true"
      ></aayn-identity-select>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Short headline(s) for ads"
      description="Compose the short headlines to be used in your ads."
    >
      <aayn-create-ad-input
        *ngFor="let control of shortHeadlinesFormArray.controls; index as index"
        [maxCharacterNumber]="30"
        [formControl]="$any(shortHeadlinesFormArray.controls[index])"
        [placeholder]="'Tell people what your ad is about'"
        [requiredMessage]="'You must write at least 1 short headline.' | translate"
        adPreview="shortHeadline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Headline(s) for ads" description="Compose the headlines to be used in your ads.">
      <aayn-create-ad-input
        *ngFor="let control of headlinesFormArray.controls; index as index"
        [maxCharacterNumber]="70"
        [formControl]="$any(headlinesFormArray.controls[index])"
        [placeholder]="'Write a short headline'"
        [requiredMessage]="'You must write at least 1 headline.' | translate"
        adPreview="headline"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Description for ads" description="Compose the descriptions to be used in your ads.">
      <aayn-create-ad-input
        *ngFor="let control of descriptionsFormArray.controls; index as index"
        [maxCharacterNumber]="adCreationModel.selectedPlatforms.includes(Platforms.Google) && index === 0 ? 60 : 70"
        [placeholder]="'Write a description here' | translate"
        [formControl]="$any(descriptionsFormArray.controls[index])"
        [previewActive]="true"
        [requiredMessage]="'You must write at least 1 description.' | translate"
        adPreview="description"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>

    <aayn-ad-type-section header="Primary Texts" description="Compose the primary texts to be used in your ads.">
      <aayn-create-ad-input
        *ngFor="let control of primaryTextsFormArray.controls; index as index"
        [maxCharacterNumber]="70"
        [placeholder]="'Write a primary text here' | translate"
        [formControl]="$any(primaryTextsFormArray.controls[index])"
        [previewActive]="true"
        [requiredMessage]="'You must write at least 1 primary text.' | translate"
        adPreview="primaryText"
        textAiSection="headlines"
      >
      </aayn-create-ad-input>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>

  <aayn-ad-type-sections>
    <aayn-ad-type-section
      header="Call to Action & Destination URL"
      description="Select the most appropriate call to action and add your destination URL."
    >
      <section class="aayn-ad-type--row aayn-ad-type--row--equal aayn-ad-type__mt-spacing">
        <ayn-form-field class="aayn-ad-type--select-call-to-action">
          <ayn-select
            formControlName="callToActionType"
            adPreview="callToAction"
            [standAlone]="true"
            [placeholder]="'Call To Action' | translate"
            [showTick]="true"
            [showSearch]="false"
            [panelMaxHeight]="210"
          >
            <ayn-option
              *ngFor="let callToAction of callToActions"
              [value]="callToAction"
              title="{{ callToAction!.name | translate }}"
            >
              {{ callToAction!.name | translate }}
            </ayn-option>
          </ayn-select>
        </ayn-form-field>

        <ayn-form-field>
          <div class="ayn-prefix">
            {{ 'URL' | translate }}
          </div>

          <input
            type="url"
            placeholder="https://adyouneed.com"
            formControlName="websiteUrl"
            ayn-input
            [ngModel]="adCreationModel.ad.websiteUrl"
            adPreview="websiteUrl"
            [standAlone]="true"
            #urlInput
          />
        </ayn-form-field>
      </section>

      <ayn-form-field
        class="aayn-ad-type--control aayn-ad-type__mt-spacing"
        *aynSmartHasPlatform="[Platforms.Google, Platforms.Bing]"
      >
        <input
          type="text"
          ayn-input
          [placeholder]="'Business Name' | translate"
          formControlName="businessName"
          adPreview="businessName"
          [standAlone]="true"
        />
      </ayn-form-field>

      <ayn-form-field class="aayn-ad-type--control aayn-ad-type__mt-spacing">
        <input type="text" ayn-input [placeholder]="'Ad Name' | translate" formControlName="name" />
      </ayn-form-field>
    </aayn-ad-type-section>
  </aayn-ad-type-sections>
</form>
