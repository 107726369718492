import { InjectionToken } from '@angular/core';

export type TextInputLengthCalculator = (value: string) => number;
export const DEFAULT_TEXT_INPUT_LENGTH_CALCULATOR: TextInputLengthCalculator = (value: string) => value.length;
export const TEXT_INPUT_LENGTH_CALCULATOR = new InjectionToken<TextInputLengthCalculator>(
  'TEXT_INPUT_LENGTH_CALCULATOR',
  {
    factory: () => DEFAULT_TEXT_INPUT_LENGTH_CALCULATOR
  }
);

export function provideLengthCalculator(calculator: TextInputLengthCalculator) {
  return { provide: TEXT_INPUT_LENGTH_CALCULATOR, useValue: calculator };
}
