import { camelCase } from 'lodash';

export function pascalCase(str: string) {
  return camelCase(str).replace(/^[a-z]/, (o) => o.toUpperCase());
}

export function parseFullName(fullname: string): { firstName: string; lastName: string } {
  const splitted = fullname.trim().split(' ');

  if (splitted.length == 1) {
    return {
      firstName: splitted.at(0)!,
      lastName: ''
    };
  }

  return {
    firstName: fullname.split(' ').slice(0, -1).join(' '),
    lastName: fullname.split(' ').slice(-1).join(' ')
  };
}

(window as any).pf = parseFullName;

export function joinFullName(lastName: string, ...firstName: string[]) {
  return firstName.join(' ') + ' ' + lastName;
}

export function joinTextWithPrefix(prefix: string, joiner: string) {
  return function _joinTextWithPrefix(args: Iterable<string | number>) {
    return prefix + joiner + Array.from(args).join(joiner);
  };
}
type ReduceStrCb<T> = (acc: T, char: string, index: number) => T;
export function reduceStr<T>(str: string, cb: ReduceStrCb<T>, defaultValue: T) {
  return Array.prototype.reduce.call<string, [ReduceStrCb<T>, T], T>(str, cb, defaultValue);
}

export function countWithCJK(str: string, cjkCount: number | ((char: string) => number)) {
  return reduceStr(
    str,
    (acc, char) => acc + (Unicode.isCJK(char) ? (typeof cjkCount === 'number' ? cjkCount : cjkCount(char)) : 1),
    0
  );
}

export function byteSize(str: string) {
  return new Blob([str]).size;
}

export module Unicode {
  export type RangeTuple = [number, number];
  export type Ranges = RangeTuple[];

  //https://github.com/echamudi/japanese-toolkit/blob/deb61fbbb20c8287d3d907b842976271aca65811/packages/kyarakuta/src/ranges.ts#L10
  const CJKRanges: Ranges = [
    [0x4e00, 0x9fff], // CJK Unified Ideographs
    [0x3400, 0x4dbf], // CJK Unified Ideographs Extension A
    [0x20000, 0x2a6df], // CJK Unified Ideographs Extension B
    [0x2a700, 0x2b73f], // CJK Unified Ideographs Extension C
    [0x2b740, 0x2b81f], // CJK Unified Ideographs Extension D
    [0x2b820, 0x2ceaf], // CJK Unified Ideographs Extension E
    [0x2ceb0, 0x2ebef], // CJK Unified Ideographs Extension F
    [0x30000, 0x3134f], // CJK Unified Ideographs Extension G
    [0xf900, 0xfaff], // CJK Compatibility Ideographs
    [0x2f800, 0x2fa1f], // CJK Compatibility Ideographs Supplement
    [0x2e80, 0x2eff], // CJK Radicals Supplement
    [0x3000, 0x303f], // CJK Symbols and Punctuation
    [0x31c0, 0x31ef], // CJK Strokes
    [0x3200, 0x32ff], // Enclosed CJK Letters and Months
    [0x3300, 0x33ff], // CJK Compatibility
    [0xfe30, 0xfe4f] // CJK Compatibility Forms
  ];

  export function isCJK(char: string): boolean {
    return isWithinRanges(char, [CJKRanges]);
  }

  function isWithinRanges(string: string, rangesCollection: Ranges[]): boolean {
    const cpArray: Readonly<number[]> = [...string].map((char) => {
      const cp = char.codePointAt(0);
      if (cp === undefined) throw new Error();
      return cp;
    });

    return cpArray.every((cp) =>
      rangesCollection.some((ranges) => ranges.some((range) => range[0] <= cp && cp <= range[1]))
    );
  }
}
